import React, { useState, useContext } from "react";

import { Box, Button } from "@mui/material";
import { RowBox } from "../components/ui";
import { PageTitle, ProfileCard, OperatorListCard } from "../components/app-ui";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AddOperatorDialog, ConfirmDialog } from "../components/dialogs";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { operatorSchema } from "../utils/validation";

import { logout } from "../store/auth";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { ProgressIndicator } from "../components/ui";
import { toast } from "react-toastify";

export default function Profile() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [shouldShowDialog, showDialog] = useState(false);
  const [shouldShowConfirm, showConfirm] = useState(false);
  const [selectedUser, setUser] = useState();
  const [deleteMessage, setDeleteMessage] = useState();

  const operatorForm = useForm({
    resolver: yupResolver(operatorSchema),
    defaultValues: {
      role: "operator",
    },
  });

  const queryClient = useQueryClient();
  const { addUser, getOperators, deleteUser } = useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["user", { role: "operator" }],
    getOperators,
    {
      select: (data) => data?.data,
    }
  );

  const addOperatorMutation = useMutation((data) => addUser(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", { role: "operator" }]);
      showDialog(false);
      toast.success("Successfully added operator");
    },
  });

  const deleteMutation = useMutation((id) => deleteUser(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", { role: "operator" }]);
      setUser(null);
      toast.success("Successfully deleted operator");
    },
  });

  const editProfileHandler = () => {
    navigate("edit");
  };

  const editUserHandler = (user) => {
    navigate(`edit/${user.id}`, { state: user });
  };

  const deleteUserHandler = (user) => {
    setUser(user);
    setDeleteMessage(`Are you sure to delete user ${user?.fullname}?`);
    showConfirm(true);
  };

  const deleteConfirmHandler = () => {
    showConfirm(false);
    deleteMutation.mutate(selectedUser.id);
  };

  const submitHandler = (values) => {
    addOperatorMutation.mutate(values);
  };

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle
        title="Profile"
        links={[
          { name: "Home", to: "/" },
          { name: "Profile", to: "/profile" },
        ]}
      />

      <ProfileCard user={user} sx={{ mt: 2.5 }} onEdit={editProfileHandler} onLogout={logoutHandler} />
      {user.role === "admin" && (
        <OperatorListCard
          sx={{ mt: 3.5 }}
          data={data}
          onAdd={() => showDialog(true)}
          onEdit={editUserHandler}
          onDelete={deleteUserHandler}
        />
      )}
      <FormProvider {...operatorForm}>
        <AddOperatorDialog
          show={shouldShowDialog}
          onCancel={() => showDialog(false)}
          onSubmit={operatorForm.handleSubmit(submitHandler)}
        />
      </FormProvider>
      <ConfirmDialog
        show={shouldShowConfirm}
        body={deleteMessage}
        buttonText="Delete"
        onConfirm={deleteConfirmHandler}
        onCancel={() => showConfirm(false)}
      />
      {(addOperatorMutation.isLoading || deleteMutation.isLoading) && (
        <ProgressIndicator />
      )}
    </Box>
  );
}
