import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { Box, Button, Card, CardContent } from "@mui/material";
import {
  ColBox,
  RowBox,
  ProgressIndicator,
  FileButton,
  SearchBar,
} from "../components/ui";

import { PageTitle } from "../components/app-ui";
import { DealerTable } from "../components/tables";
import { ConfirmDialog } from "../components/dialogs";
import { useAdminOTP } from "../components/hooks";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import AddIcon from "@mui/icons-material/Add";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

import { xlsMimeTypes } from "../config";
import { downloadAsFile } from "../utils/utils";

export default function Dealers() {
  const [page, setPage] = useState(0);
  const [q, setQuery] = useState();
  const [selectionModel, setSelectionModel] = useState();
  const [isShowingConfirm, showConfirm] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { getDealers, bulkUploadDealers, downloadDealers, bulkDeleteDealers } =
    useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["users", { role: "dealer", page, q }],
    () => getDealers(page, q),
    {
      select: (data) => data?.data,
      keepPreviousData: true,
    }
  );

  const uploadMutation = useMutation((data) => bulkUploadDealers(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users", { role: "dealer" }]);
      const blob = data?.data;
      if (blob?.size > 0) {
        downloadAsFile(data?.data, "Dealer-BulkUpload-Report.xlsx");
        toast.error(
          "Dealer Upload completed with errors. Downloading error report"
        );
      } else {
        toast.success("Successfully uploaded the dealers");
      }
    },
  });

  const downloadMutation = useMutation(() => downloadDealers(), {
    onSuccess: (data) => {
      const blob = data?.data;
      if (blob?.size > 0) {
        downloadAsFile(data?.data, "Dealer-Report.xlsx");
        toast.success("Successfully downloaded the dealers");
      } else {
        toast.error("No Data available to downloaded");
      }
    },
  });

  const deleteMutation = useMutation(() => bulkDeleteDealers(selectionModel), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users", { role: "dealer" }]);
      setSelectionModel([])
      toast.success("Successfully deleted the dealers");
    },
  });

  const addDealerHandler = () => {
    navigate(`new`);
  };

  const editHandler = (user) => {
    navigate(`edit/${user.id}`, { state: user });
  };

  const uploadHanlder = (file) => {
    uploadMutation.mutate({ file });
  };

  const deleteHandler = () => {
    deleteMutation.mutate()
  };

  const [DeleteOTPDialog, showDeleteOTP] = useAdminOTP(deleteHandler);

  const downloadHandler = () => {
    downloadMutation.mutate();
  };

  return (
    <ColBox
      sx={{
        pt: 2,
        px: 2,
        height: "calc(100vh - 87px)",
        boxSizing: "border-box",
      }}
    >
      <PageTitle title="Dealers" />

      <RowBox sx={{ my: 2 }} fullWidth>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={addDealerHandler}
        >
          Add Dealer
        </Button>
        <FileButton fileType={xlsMimeTypes} onSelect={uploadHanlder}>
          <Button
            variant="contained"
            startIcon={<CloudUploadOutlinedIcon />}
            sx={{ ml: 1.5 }}
          >
            Bulk Upload
          </Button>
        </FileButton>

        <Button
          variant="contained"
          startIcon={<CloudDownloadOutlinedIcon />}
          sx={{ ml: 1.5 }}
          onClick={downloadHandler}
        >
          Download
        </Button>
        <SearchBar
          sx={{ ml: "auto", backgroundColor: "white" }}
          value={q}
          onChange={(q) => {
            setQuery(q);
            setPage(0);
          }}
        />
      </RowBox>

      <Card
        sx={{
          mb: 0,
          display: "flex",
          width: "100%",
          flexGrow: 1,
        }}
      >
        <CardContent
          sx={{
            p: 0,
            ":last-child": { pb: 0 },
            display: "flex",
            flexGrow: 1,
          }}
        >
          <DealerTable
            data={data}
            page={page}
            onPageChange={setPage}
            onEdit={editHandler}
            onDelete={() => showConfirm(true)}
            selectionModel={selectionModel}
            onSelectionModelChange={setSelectionModel}
          />
        </CardContent>
      </Card>

      <DeleteOTPDialog />
      <ConfirmDialog
        show={isShowingConfirm}
        body={`Are you sure to delete ?`}
        buttonText="Delete"
        onConfirm={() => {
          showConfirm(false);
          showDeleteOTP();
        }}
        onCancel={() => showConfirm(false)}
      />

      {(isLoading ||
        downloadMutation.isLoading ||
        uploadMutation.isLoading) && <ProgressIndicator />}
    </ColBox>
  );
}
