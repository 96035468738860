import React, { useContext } from "react";

import { Box, Grid, Card, Typography, Button } from "@mui/material";
import { RowBox, InputField, GridItems, StatePicker } from "../components/ui";
import { CreateDealerCard, PageTitle } from "../components/app-ui";

import { useNavigate } from "react-router";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dealerSchema, profileSchema } from "../utils/validation";

//API related imports
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { ProgressIndicator } from "../components/ui";
import { toast } from "react-toastify";
import { DealerForm } from "../components/forms";
import { useCategory } from "../components/hooks";

export default function DealerCreate() {
  const navigate = useNavigate();
  const categories = useCategory();

  const queryClient = useQueryClient();
  const { addUser } = useContext(APIContext);

  const addMutation = useMutation((data) => addUser(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", { role: "dealer" }]);
      toast.success("Successfully added the dealer");
      navigate(-1);
    },
  });

  const form = useForm({
    resolver: yupResolver(dealerSchema(categories)),
  });

  const submitHandler = (values) => {
    values.role = "dealer";
    addMutation.mutate(values);
  };

  const errorHandler = (error) => {
    console.log(error);
  };

  const cancelHandler = () => {
    form.reset();
    navigate(-1);
  };
  const settings = {
    wsx: { mt: 1.6 },
    titleVariant: "h5",
  };

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle
        title="Add New Dealer"
        links={[
          { name: "Home", to: "/" },
          { name: "Dealers", to: "/dealers" },
          { name: "Add New Dealer", to: `/dealers/new` },
        ]}
        sx={{ mb: 2 }}
      />

      <FormProvider {...form}>
        <DealerForm />

        <RowBox fullWidth sx={{ mt: 4 }} center>
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelHandler}
            sx={{ mr: 1.5 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={form.handleSubmit(submitHandler, errorHandler)}
          >
            Add Dealer
          </Button>
        </RowBox>
      </FormProvider>
      {addMutation.isLoading && <ProgressIndicator />}
    </Box>
  );
}
