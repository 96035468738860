import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
} from "@mui/material";

import { CatalogueForm, OperatorForm, SalesForm, OfferForm } from "./forms";

import { OTPInputView, RowBox } from "./ui";

import CancelIcon from "@mui/icons-material/Cancel";

export const AddOperatorDialog = (props) => {
  return (
    <Dialog open={props?.show} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Add Admin/Operator</Typography>
        <IconButton onClick={props?.onCancel}>
          <CancelIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <OperatorForm />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ mr: 1.5, ml: "auto" }}
          onClick={props?.onCancel}
          color="secondary"
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={props?.onSubmit}>
          Add Operator
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddSalesDialog = (props) => {
  return (
    <Dialog open={props?.show} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Add Marketing Coordinator</Typography>
        <IconButton onClick={props?.onCancel}>
          <CancelIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SalesForm />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ mr: 1.5, ml: "auto" }}
          onClick={props?.onCancel}
          color="secondary"
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={props?.onSubmit}>
          Add Marketing Coordinator
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddCatalogueDialog = (props) => {
  return (
    <Dialog open={props?.show} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Add Catalogue</Typography>
        <IconButton onClick={props?.onCancel}>
          <CancelIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CatalogueForm />
        <RowBox>
          <Button
            variant="contained"
            sx={{ mr: 1.5, ml: "auto" }}
            onClick={props?.onCancel}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={props?.onSubmit}>
            {props?.isEdit ? "Save Changes" : "Add Catalogue"}
          </Button>
        </RowBox>
      </DialogContent>
    </Dialog>
  );
};

export const AddOfferDialog = (props) => {
  return (
    <Dialog open={props?.show} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Add Offer</Typography>
        <IconButton onClick={props?.onCancel}>
          <CancelIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <OfferForm />
        <RowBox>
          <Button
            variant="contained"
            sx={{ mr: 1.5, ml: "auto" }}
            onClick={props?.onCancel}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={props?.onSubmit}>
            {props?.isEdit ? "Save Changes" : "Add Offer"}
          </Button>
        </RowBox>
      </DialogContent>
    </Dialog>
  );
};

export const ConfirmDialog = (props) => {
  return (
    <Dialog
      open={props?.show}
      fullWidth
      maxWidth="sm"
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>
        <Typography variant="h4">{props?.title || "Confirmation"}</Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 2.5 }}>
        <Typography variant="body1" color="warning">
          {props?.body}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
        <Button
          variant="contained"
          color="warning"
          onClick={props?.onConfirm}
          sx={{ mr: 1 }}
        >
          {props?.buttonText || "Yes"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={props?.onCancel}
          sx={{ ml: 1 }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const OTPDialog = (props) => {
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (otp?.length === 4) {
      setOtp("")
      props?.onConfirm()
    }
  }, [otp]);

  return (
    <Dialog
      open={props?.show}
      fullWidth
      maxWidth="sm"
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>
        <Typography variant="h4">
          {props?.title || "Confirm Action with OTP"}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ mt: 2.5, display: "flex", justifyContent: "center" }}
      >
        <OTPInputView onChange={setOtp} value={otp} shouldAutoFocus />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={props?.onConfirm}
          sx={{ mr: 1 }}
        >
          Resend OTP
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={props?.onCancel}
          sx={{ ml: 1 }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
