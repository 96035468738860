import React from "react";
import { Navigate, useLocation, Route, Routes } from "react-router";
import { useSelector } from "react-redux";

import App from "./App";
import Login from "./pages/login";
import Profile from "./pages/profile";
import EditProfile from "./pages/user-edit";
import EditUserProfile from "./pages/operator-edit";
import SalesList from "./pages/sales-list";
import EditSalesExecutive from "./pages/sales-edit";
import Catalogue from "./pages/catalogue";
import Dealers from "./pages/dealers";
import DealerCreate from "./pages/dealer-create";
import DealerEdit from "./pages/dealer-edit";
import Products from "./pages/products";
import ProductCreate from "./pages/product-create";
import ProductEdit from "./pages/product-edit";
import Orders from "./pages/orders";
import OrderEdit from "./pages/order-edit";
import Offer from "./pages/offer";
import Activity from "./pages/activity";

const AuthRoute = (props) => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  if (!auth?.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return props?.children;
};

export default function Root() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthRoute>
            <App />
          </AuthRoute>
        }
      >
        <Route index element={<Navigate to="products" />} />
        <Route path="profile" element={<Profile />} />
        <Route path="profile/edit" element={<EditProfile />} />
        <Route path="profile/edit/:id" element={<EditUserProfile />} />
        <Route path="products" element={<Products />} />
        <Route path="products/new" element={<ProductCreate />} />
        <Route path="products/edit/:id" element={<ProductEdit />} />
        <Route path="orders" element={<Orders />} />
        <Route path="orders/edit/:id" element={<OrderEdit />} />
        <Route path="sales" element={<SalesList />} />
        <Route path="sales/edit/:id" element={<EditSalesExecutive />} />
        <Route path="dealers" element={<Dealers />} />
        <Route path="dealers/new" element={<DealerCreate />} />
        <Route path="dealers/edit/:id" element={<DealerEdit />} />
        <Route path="catalogue" element={<Catalogue />} />
        <Route path="offer" element={<Offer />} />
        <Route path="activity" element={<Activity />} />
      </Route>

      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

const NoMatch = () => {
  return <div>Nothing to show</div>;
};

const Dummy = () => {
  return <div>Hello there</div>;
};

const Mummy = () => {
  return <div>Group Home</div>;
};
