import React, { useContext } from "react";

import {Button} from "@mui/material";

import { Box, Typography, Card, useTheme } from "@mui/material";
import {
  PageTitle,
  ProductImageController,
} from "../components/app-ui";

import { RowBox, ProgressIndicator } from "../components/ui";
import {
  FaucetProductForm,
  KitchenProductForm,
  ProductInfoForm,
  SanitaryProductForm,
  BathWareProductForm,
} from "../components/forms";

import { useNavigate } from "react-router";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema } from "../utils/validation";

//API related imports
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import { toast } from "react-toastify";

export default function ProductCreate() {
  const navigate = useNavigate();
  const theme = useTheme();

  const queryClient = useQueryClient();
  const { createProduct } = useContext(APIContext);

  const addMutation = useMutation((data) => createProduct(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["products"]);
      toast.success("Successfully added the product");
      navigate(-1);
    },
  });

  const form = useForm({
    resolver: yupResolver(productSchema),
  });

  const type = form.watch("type");

  const productForm = {
    "Faucet": <FaucetProductForm />,
    "Bath Ware": <BathWareProductForm />,
    "Sanitary Ware": <SanitaryProductForm />,
    "Kitchen Sink": <KitchenProductForm />,
    "Mirror": <FaucetProductForm />,
    "Nobero": <FaucetProductForm />,
    "PTMT": <FaucetProductForm />,
    "Water Heater": <FaucetProductForm />
  };

  const submitHandler = (values) => {
    addMutation.mutate(values);
  };

  const errorHandler = (error) => {
    console.log(error);
  };

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle
        title="Add New Product"
        links={[
          { name: "Home", to: "/" },
          { name: "Products", to: "/products" },
          { name: "Add New Product", to: `/products/new` },
        ]}
        sx={{ mb: 2 }}
      />

      <FormProvider {...form}>
        {/* <Card sx={{ p: 2.5 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Product Images
          </Typography>
          <ProductImageController
            name="images"
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "60%",
              },
            }}
          />
        </Card> */}
        <Card sx={{ p: 2.5, mt: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Product Details
          </Typography>
          <ProductInfoForm />
        </Card>

        {type && (
          <Card sx={{ p: 2.5, mt: 2 }}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              Product Information
            </Typography>
            {productForm[type]}
          </Card>
        )}

        <RowBox sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={{ mr: 1.5 }}
            onClick={form.handleSubmit(submitHandler)}
          >
            Save Changes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => form.reset()}
          >
            Cancel
          </Button>
        </RowBox>
      </FormProvider>
      {addMutation.isLoading && <ProgressIndicator />}
    </Box>
  );
}
