import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { Box, Button, Card, CardContent } from "@mui/material";

import { RowBox, ColBox, ProgressIndicator } from "../components/ui";

import { PageTitle } from "../components/app-ui";
import { ActivityTable } from "../components/tables";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

export default function Activity() {
  const [page, setPage] = useState(0);
  const [q, setQuery] = useState();
  const [product, setProduct] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [sort, setSortBy] = useState();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { getActivities } = useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["activity", { page }],
    () => getActivities(page),
    {
      select: (data) => data?.data,
      keepPreviousData: true,
    }
  );

  const clearFilters = () => {
    setQuery();
    setProduct();
    setSortBy();
    setStartDate();
    setEndDate();
  };

  const viewHandler = (activity) => {
    if (activity?.refUser?.id) {
      const refUser = activity?.refUser
      if (refUser?.role === 'operator') {
        navigate(`/profile/edit/${refUser?.id}`, {state: refUser})
      } else if (refUser?.role === 'dealer') {
        navigate(`/dealers/edit/${refUser?.id}`, {state: refUser})
      }  else if (refUser?.role === 'sales') {
        navigate(`/sales/edit/${refUser?.id}`, {state: refUser})
      }
    } else if (activity?.product?.id) {
      navigate(`/products/edit/${activity?.product?.id}`, {state: activity?.product})
    } else if (activity?.dealer?.id) {
      navigate(`/dealers/edit/${activity?.dealer?.id}`, {state: activity?.dealer})
    } else if (activity?.order?.id) {
      navigate(`/orders/edit/${activity?.order?.id}`, {state: activity?.order})
    }
  }

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle title="Activities" />

      <Card sx={{ mb: 0 }}>
        <CardContent sx={{ p: 0, ":last-child": { pb: 0 } }}>
          <ActivityTable
            data={data}
            page={page}
            onPageChange={setPage}
            onView={viewHandler}
          />
        </CardContent>
      </Card>

      {isLoading && <ProgressIndicator />}
    </Box>
  );
}
