import React, { useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Card,
  Typography,
  Select,
} from "@mui/material";
import {} from "@mui/x-date-pickers";

import {
  ColBox,
  DateController,
  FileDrop,
  FileDropController,
  GridItems,
  InputField,
  RowBox,
  SelectController,
  StatePicker,
  DatePicker,
  DatePickerController,
} from "./ui";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTheme } from "@emotion/react";
import { useCategory } from "./hooks";
import { statusOptions, categories } from "../config";

export const PasswordField = (props) => {
  const [showPassword, setPassword] = useState();

  const handleClickShowPassword = () => {
    setPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <InputField
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export const LoginForm = (props) => {
  const theme = useTheme();
  const [showPassword, setPassword] = useState();

  return (
    <ColBox component="form" noValidate autoComplete="off" sx={props?.sx}>
      <InputField name="email" title="Email" placeholder="Enter your email" />
      <PasswordField
        placeholder="Enter your password"
        title="Password"
        name="password"
        wsx={{ mt: 2 }}
      />
    </ColBox>
  );
};

export const EditPasswordForm = (props) => {
  return (
    <ColBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      {/* <PasswordField
        name="oldPassword"
        title="Old Password"
        titleVariant="h5"
      /> */}
      <PasswordField
        name="password"
        title="New Password"
        titleVariant="h5"
        wsx={{ mt: 1.6 }}
      />
      <PasswordField
        name="confirmPassword"
        title="Confirm Password"
        titleVariant="h5"
        wsx={{ mt: 1.6 }}
      />
    </ColBox>
  );
};

export const EditProfileForm = (props) => {
  const settings = {
    wsx: { mt: 1.6 },
    titleVariant: "h5",
  };

  return (
    <ColBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <InputField name="firstname" title="First Name" {...settings} />
      <InputField name="lastname" title="Last Name" {...settings} />
      <InputField name="phoneNo" title="Mobile Number" {...settings} />
      <InputField name="email" title="Email" {...settings} />
      <InputField
        name="address"
        title="Address"
        multiline
        maxRows={4}
        {...settings}
      />
    </ColBox>
  );
};

export const OperatorForm = (props) => {
  const settings = {
    wsx: { mt: 1.6 },
    titleVariant: "h5",
  };

  const roleOptions = [
    { name: "Operator", value: "operator" },
    { name: "Admin", value: "admin" },
  ];

  return (
    <ColBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <InputField name="firstname" title="First Name" {...settings} />
      <InputField name="lastname" title="Last Name" {...settings} />
      <InputField name="phoneNo" title="Mobile Number" {...settings} />
      <InputField name="email" title="Email" {...settings} />
      <InputField
        name="address"
        title="Address"
        multiline
        maxRows={4}
        {...settings}
      />
      <SelectController
        name="role"
        options={roleOptions}
        title="Role"
        fullWidth
        placeholder="Select User Role"
        {...settings}
      />
    </ColBox>
  );
};

export const SalesForm = (props) => {
  const settings = {
    wsx: { mt: 1.6 },
    titleVariant: "h5",
  };

  return (
    <ColBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <InputField name="firstname" title="First Name" {...settings} />
      <InputField name="lastname" title="Last Name" {...settings} />
      <InputField name="phoneNo" title="Mobile Number" {...settings} />
      <InputField name="email" title="Email" {...settings} />
      <InputField
        name="address"
        title="Address"
        multiline
        maxRows={4}
        {...settings}
      />
    </ColBox>
  );
};

export const CatalogueForm = (props) => {
  const settings = {
    wsx: { mt: 1.6 },
    titleVariant: "h5",
  };

  return (
    <ColBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <InputField name="title" title="Title" {...settings} />
      <InputField
        name="description"
        title="description"
        multiline
        maxRows={4}
        {...settings}
      />
      <FileDropController
        name="doc"
        sx={{ my: 1.6 }}
        fileType="doc"
        buttonText="Upload a PDF File"
      />
    </ColBox>
  );
};

export const OfferForm = (props) => {
  const settings = {
    wsx: { mt: 1.6 },
    titleVariant: "h5",
  };

  return (
    <ColBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <InputField name="title" title="Title" {...settings} />
      <InputField
        name="description"
        title="description"
        multiline
        maxRows={4}
        {...settings}
      />
      <DatePickerController name="validFrom" title="Start Date" {...settings}  placeholder="Select Date" />
      <DatePickerController name="validUntil" title="End Date" {...settings} placeholder="Select Date" />
      <FileDropController
        name="image"
        sx={{ my: 1.6 }}
        fileType="all"
        buttonText="Upload a photo or PDF File"
      />
    </ColBox>
  );
};

export const DealerForm = (props) => {
  const settings = {
    wsx: { mt: 1.6 },
    titleVariant: "h5",
  };
  const categories = useCategory();

  return (
    <>
      <Card sx={{ p: 2.5, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Dealer Information
        </Typography>
        <Grid container spacing={2}>
          <GridItems xs={12} sm={6} md={4} lg={3}>
            <InputField name="alias" title="Dealer Alias" {...settings} />
            <InputField name="name" title="Dealer Name" {...settings} />
            <InputField name="email" title="Email" {...settings} />
            <InputField name="parent" title="Parent Region" {...settings} />
          </GridItems>
        </Grid>
      </Card>

      <Card sx={{ p: 2.5, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Location Information
        </Typography>
        <Grid container spacing={2}>
          <GridItems xs={12} sm={6} md={4} lg={3}>
            <InputField
              name="address"
              title="Address"
              multiline
              rows={4}
              {...settings}
            />
            <StatePicker
              name="state"
              country="India"
              title="State"
              {...settings}
            />
            <InputField name="pinCode" title="Pincode" {...settings} />
            <InputField
              name="ledgerPhone1"
              title="Ledger Phone No"
              {...settings}
            />
          </GridItems>
        </Grid>
      </Card>

      <Card sx={{ p: 2.5, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Contact Information
        </Typography>
        <Grid container spacing={2}>
          <GridItems xs={12} sm={6} md={4} lg={3}>
            <InputField
              name="contact"
              title="Dealer Contact Person Name"
              {...settings}
            />
            <InputField name="phoneNo" title="Mobile Number" {...settings} />
            <InputField
              name="phoneNo2"
              title="Mobile Number (alternate)"
              {...settings}
            />
          </GridItems>
        </Grid>
      </Card>

      <Card sx={{ p: 2.5, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Tax Information
        </Typography>
        <Grid container spacing={2}>
          <GridItems xs={12} sm={6} md={4} lg={3}>
            <InputField
              name="incomeTaxNo"
              title="Income Tax No"
              {...settings}
            />
            <InputField name="gstNumber" title="GST No" {...settings} />
          </GridItems>
        </Grid>
      </Card>

      <Card sx={{ p: 2.5, mb: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Pricing Information
        </Typography>
        <InputField name="pricingLevel" title="Pricing Level" {...settings} />
        <Typography variant="h4" sx={{ mt: 2 }}>
          Trade Discount (in %)
        </Typography>
        <Grid container spacing={2}>
          <GridItems xs={12} sm={6} md={4} lg={3}>
            {categories?.map((c) => (
              <InputField
                name={`tradeDiscount.${c.name}`}
                title={c.name}
                {...settings}
              />
            ))}
          </GridItems>
        </Grid>
      </Card>
    </>
  );
};

export const ProductInfoForm = (props) => {
  const options = [""]
  const settings = {
    titleVariant: "h5",
  };

  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <GridItems xs={12} sm={6} md={4}>
          <SelectController
            name="type"
            title="Category"
            placeholder="Select Category"
            options={categories}
            {...settings}
          />
          <InputField name="name" title="Product Name" {...settings} />
          <InputField name="title" title="Product Title" {...settings} />
        </GridItems>
      </Grid>
    </RowBox>
  );
};

export const FaucetProductForm = (props) => {
  const settings = {
    titleVariant: "h5",
  };

  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <GridItems xs={12} sm={6}>
          <SelectController
            name="category"
            title="Sub Category"
            placeholder="Select Sub Category"
            options={["Taps", "Stop Cocks", "Mixers"]}
            {...settings}
          />
          <InputField name="description" title="Description" {...settings} />
          <InputField
            name="catalogueNo"
            title="Catalogue Number"
            {...settings}
          />
          <InputField name="price" title="Price" {...settings} />
        </GridItems>
      </Grid>
    </RowBox>
  );
};

export const BathWareProductForm = (props) => {
  const settings = {
    titleVariant: "h5",
  };

  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <GridItems xs={12} sm={6}>
          <SelectController
            name="category"
            title="Sub Category"
            placeholder="Select Sub Category"
            options={["Taps", "Stop Cocks", "Mixers"]}
            {...settings}
          />
          <SelectController
            name="head"
            title="Head Type"
            placeholder="Select head type"
            options={["Jalangi", "Spa", "Pearl"]}
            {...settings}
          />
          <InputField name="description" title="Description" {...settings} />
          <InputField
            name="catalogueNo"
            title="Catalogue Number"
            {...settings}
          />
          <InputField name="price" title="Price" {...settings} />
        </GridItems>
      </Grid>
    </RowBox>
  );
};

export const SanitaryProductForm = (props) => {
  const settings = {
    titleVariant: "h5",
  };

  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <GridItems xs={12} sm={6}>
          <SelectController
            name="color"
            title="Color"
            placeholder="Select Color"
            options={["White", "Ivory"]}
            {...settings}
          />
          <InputField name="description" title="Description" {...settings} />
          <InputField
            name="catalogueNo"
            title="Category Number"
            {...settings}
          />
          <InputField name="price" title="Price" {...settings} />
        </GridItems>
      </Grid>
    </RowBox>
  );
};

export const KitchenProductForm = (props) => {
  const settings = {
    titleVariant: "h5",
  };
  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <GridItems xs={12} sm={6}>
          <SelectController
            name="size"
            title="Size"
            placeholder="Select Size"
            options={["18*16*8", "18*16*9", "20*17*8", "21*18*8", "21*18*9"]}
            {...settings}
          />
          <SelectController
            name="ssGrade"
            title="SS Grade"
            placeholder="Select grade"
            options={["304", "201"]}
            {...settings}
          />
          <SelectController
            name="finish"
            title="Finish"
            placeholder="Select Finish"
            options={["Gloss", "Matt"]}
            {...settings}
          />
          <SelectController
            name="thickness"
            title="Thickness (in mm)"
            placeholder="Select Thickness"
            options={["1", "0.7"]}
            {...settings}
          />
          <SelectController
            name="depth"
            title="Depth (in inches)"
            placeholder="Select Depth"
            options={["8", "9"]}
            {...settings}
          />
          <InputField name="bowl" title="Bowl" {...settings} />
          <InputField name="description" title="Description" {...settings} />
          <InputField
            name="catalogueNo"
            title="Category Number"
            {...settings}
          />
          <InputField name="price" title="Price" {...settings} />
        </GridItems>
      </Grid>
    </RowBox>
  );
};

export const OrderSummaryForm = (props) => {
  const settings = {
    titleVariant: "h5",
    disabled: true,
  };

  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <GridItems xs={12} sm={6} md={4}>
          <InputField name="voucherNo" title="Voucher No" {...settings} />
          <InputField name="orderNo" title="Order Id" {...settings} />
          <DateController name="date" title="Order Date" {...settings} />
        </GridItems>
      </Grid>
    </RowBox>
  );
};

export const OrderProductForm = (props) => {
  const { prefix } = props;
  const settings = {
    titleVariant: "h5",
    disabled: true,
  };
  
  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <GridItems xs={12} sm={6} md={4}>
          <InputField
            name={prefix + "product.catalogueNo"}
            title="Cat. No"
            {...settings}
          />
          <InputField
            name={prefix + "product.name"}
            title="Product Name"
            {...settings}
          />
          <InputField
            name={prefix + "product.description"}
            title="Description"
            {...settings}
          />
          <InputField name={prefix + "unitPrice"} title="Price" {...settings} />
          <InputField name={prefix + "qty"} title="Quantity" {...settings} />
          <InputField name="discount" title="Discount (%)" {...settings} />
          <InputField
            name={prefix + "price"}
            title="Total Price"
            {...settings}
          />
          <InputField
            name={prefix + "netPrice"}
            title="Net Price"
            {...settings}
          />
        </GridItems>
      </Grid>
    </RowBox>
  );
};

export const OrderDealerForm = (props) => {
  const settings = {
    titleVariant: "h5",
    disabled: true,
  };

  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <GridItems xs={12} sm={6} md={4}>
          <InputField name="alias" title="Alias" {...settings} />
          <InputField name="name" title="Dealer Name" {...settings} />
          <InputField name="ledgerPhone" title="Ledger Phone" {...settings} />
          <InputField name="contact" title="Dealer Contact" {...settings} />
          <InputField name="phoneNo" title="Dealer Mobile" {...settings} />
          <InputField name="address" title="Address" multiline {...settings} />
        </GridItems>
      </Grid>
    </RowBox>
  );
};

export const OrderActionForm = (props) => {
  const settings = {
    titleVariant: "h5",
    disabled: true,
  };

  return (
    <RowBox
      component="form"
      noValidate
      autoComplete="off"
      fullWidth
      sx={props?.sx}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <SelectController name="status" title="Status" options={statusOptions} />
        </Grid>
      </Grid>
    </RowBox>
  );
};
