import React, { useState } from "react";
import { Grid, Drawer, Hidden } from "@mui/material";

import { Outlet, useNavigate } from "react-router";

import { SideBar, NavBar } from "./components/app-ui";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

function App() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [showSideBar, setSideBar] = useState();

  const toggleDrawer = () => {
    setSideBar((prev) => !prev);
  };

  const profileClickHandler = () => {
    navigate("/profile");
  };

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.main,
        }}
      >
        <Hidden mdDown>
          <Grid item sm={2}>
            <SideBar />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={10} sx={{pb: 2}}>
          <NavBar
            onMenu={toggleDrawer}
            user={user}
            onProfileClick={profileClickHandler}
          />
          <Outlet />
        </Grid>
      </Grid>
      <Drawer
        variant="temporary"
        open={showSideBar}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SideBar onSelect={toggleDrawer} sx={{ ml: 0 }} />
      </Drawer>
    </>
  );
}

export default App;