import dayjs from "dayjs";
import data from "../data/countries+states";
import mime from "mime";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

/* String Manipulation Utils - BEGIN */

export const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/* String Manipulation Utils - END */

/*** API Related - BEGIN */
export const getDirtyFields = (values, dirtyFields) => {
  let updatedFields = {};
  for (let key in dirtyFields) {
    updatedFields[key] = values[key];
  }

  return Object.keys(updatedFields)?.length > 0 ? updatedFields : null;
};

export const downloadAsFile = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
};

/*** API Related - END */

/*** File Related - BEGIN */
export const getMimeType = (file) => {
  if (file instanceof File) {
    return file.type;
  } else {
    return mime.getType(file);
  }
};
/*** File Related - END */

/*** Country/City/State - BEGIN  ****/
export const getAllCountries = () => {
  return data.map((country) => country.name);
};

export const getStatesOfCountry = (name) => {
  const country = data.find((c) => c.name === name);
  return country?.states.map((s) => s.name);
};

export const getCountryCode = (name) => {
  const country = data.find((c) => c.name === name);
  return country.iso2;
};

export const getStateCode = (country, state) => {
  const _country = data.find((c) => c.name === country);
  const _state = _country?.states.find((s) => s.name === state);
  return _state?.state_code;
};
/*** Country/City/State - END  ****/

/*** Time Related - BEGIN  ****/
export const dateString = (date) => {
  return dayjs(date || dayjs()).format("MMMM D, YYYY");
};

export const dateTimeString = (date) => {
  return dayjs(date || dayjs())
    .tz("Asia/Calcutta", true)
    .format("MMM D, YYYY hh:mm A");
};

export const inputDateString = (date) => {
  return dayjs(date || dayjs()).format("YYYY-MM-DDThh:mm");
};

export const combineDateTime = (date, time) => {
  if (!date || !time) return date;
  return dayjs(date)
    .set("h", time.get("h"))
    .set("m", time.get("s"))
    .set("s", time.get("s"));
};

export const startOfDay = () =>
  dayjs().startOf("day").utc(true).format("YYYY-MM-DDTHH:mm:ss");

/*** Time Related - END  ****/

/*** Money Related - BEGIN  ****/
export const currencyString = (value) => {
  return value?.toLocaleString('en-IN');
};

/*** Money Related - END  ****/

/*** Functionality Related - BEGIN  ****/
export const activityString = (activity) => {
  const user = activity?.user;
  switch (activity?.category) {
    case "profile":
      const refUser = activity?.refUser;
      switch (activity.action) {
        case "create":
          if (refUser?.role === "sales") {
            return `${activity?.name} created new Marketing Coordinator ${refUser?.fullname}`;
          } else if (refUser?.role === "operator") {
            return `${activity?.name} created new Operator ${refUser?.fullname}`;
          } else if (refUser?.role === "dealer") {
            return `${activity?.name} created new Dealer ${refUser?.name}`;
          }
          break;
        case "edit":
          if (user.id === refUser?.id)
            return `${activity?.name} edited his profile`;
          else if (refUser?.role === "sales") {
            return `${activity.name} edited profile of Marketing Coordinator ${refUser?.fullname}`;
          } else if (refUser?.role === "operator") {
            return `${activity?.name} edited profile of Operator ${refUser?.fullname}`;
          } else if (refUser?.role === "dealer") {
            return `${activity?.name} edited profile of Dealer ${refUser?.name}`;
          }
          break;
        case "bulkCreate":
          return `${activity.name} bulk uploaded dealers`;
          break;
        case "download":
          return `${activity.name} bulk downloaded dealers data`;
          break;
      }
      break;

    case "product":
      const product = activity?.product;
      switch (activity.action) {
        case "create":
          return `${activity.name} created new Product ${product?.name}`;
        case "edit":
          return `${activity.name} edited Product ${product?.name}`;
        case "bulkCreate":
          return `${activity.name} bulk created products`;
        case "download":
          return `${activity.name} bulk downloaded product data`;
      }
      break;

    case "order":
      const order = activity?.order;
      switch (activity.action) {
        case "edit":
          return `${activity.name} edited order ${order?.orderNo}`;
        case "download":
          return `${activity.name} bulk downloaded order data`;
      }
      break;

    case "catalogue":
      const catalogue = activity?.catalogue;
      switch (activity.action) {
        case "create":
          return `${activity.name} created new catalogue ${catalogue?.title}`;
        case "edit":
          return `${activity.name} edited catalogue ${catalogue?.title}`;
        case "delete":
          return `${activity.name} deleted catalogue ${catalogue?.title}`;
      }
      break;

    case "offer":
      const offer = activity?.offer;
      switch (activity.action) {
        case "create":
          return `${activity.name} created new offer ${offer?.title}`;
        case "edit":
          return `${activity.name} edited offer ${offer?.title}`;
        case "delete":
          return `${activity.name} deleted offer ${offer?.title}`;
      }
      break;
  }
  return activity?.category + " " + activity?.action;
};

/*** Money Related - END  ****/
