import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { Box, Button, Grid } from "@mui/material";
import { ProgressIndicator, RowBox, GridItems } from "../components/ui";
import { PageTitle, CatalogueCard } from "../components/app-ui";
import { AddCatalogueDialog, ConfirmDialog } from "../components/dialogs";
import { SalesTable } from "../components/tables";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getDirtyFields } from "../utils/utils";
import { catalogueSchema } from "../utils/validation";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import AddIcon from "@mui/icons-material/Add";

export default function Catalogue() {
  const [page, setPage] = useState(0);
  const [catalogue, setCatalogue] = useState();
  const [shouldShowDialog, showDialog] = useState(false);
  const [shouldShowConfirm, showConfirm] = useState(false);
  const [selectedUser, setUser] = useState();
  const [deleteMessage, setDeleteMessage] = useState();

  const queryClient = useQueryClient();
  const { getCatalogues, createCatalogue, editCatalogue, deleteCatalogue } =
    useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["catalogue"],
    () => getCatalogues(page),
    {
      select: (data) => data?.data,
      keepPreviousData: true,
    }
  );

  const form = useForm({
    resolver: yupResolver(catalogueSchema),
    defaultValues: {
      //Note: Reset only works if defaultvalues are specified.
      title: catalogue?.title,
      description: catalogue?.description,
      doc: catalogue?.url,
    },
  });

  const addMutation = useMutation((data) => createCatalogue(data), {
    onSuccess: (data) => {
      showDialog(false);
      queryClient.invalidateQueries(["catalogue"]);
      toast.success("Successfully created catalogue");
    },
  });

  const editMutation = useMutation(({ id, data }) => editCatalogue(id, data), {
    onSuccess: (data) => {
      showDialog(false);
      queryClient.invalidateQueries(["catalogue"]);
      toast.success("Successfully saved changes");
    },
  });

  const deleteMutation = useMutation(() => deleteCatalogue(catalogue?.id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["catalogue"]);
      toast.success("Successfully deleted catalogue");
    },
  });

  const dirtyFields = form.formState.dirtyFields;
  const submitHandler = async (values) => {
    if (catalogue) {
      const data = getDirtyFields(values, dirtyFields);
      if (data) {
        if (data?.doc) {
          const canvasElement = document.querySelector(
            ".react-pdf__Page canvas"
          );
          data.thumb = await canvasToBlob(canvasElement, 'image/png') 
        }
        editMutation.mutate({ id: catalogue?.id, data });
      } else {
        toast.error("No changes to save");
      }
    } else {
      //TODO: Check why we are sending null in API
      if (!values.description) {
        delete values?.description;
      }
      const canvasElement = document.querySelector(".react-pdf__Page canvas");
      values.thumb = await canvasToBlob(canvasElement, 'image/png')
      addMutation.mutate(values);
    }
  };

  const errorHandler = (error) => {
    console.log("Error ", error);
  };

  const editHandler = (catalogue) => {
    setCatalogue(catalogue);
    const { title, description, url } = catalogue;
    form.reset({ title, description, doc: url });
    showDialog(true);
  };

  const deleteHandler = (catalogue) => {
    setCatalogue(catalogue);
    showConfirm(true);
  };

  const deleteConfirmHandler = () => {
    showConfirm(false);
    deleteMutation.mutate();
  };

  const enableHandler = (catalogue) => {
    console.log("Enable ", catalogue);
    setCatalogue(catalogue);
    editMutation.mutate({
      id: catalogue?.id,
      data: { isDisabled: !catalogue.isDisabled },
    });
  };

  const Catalogues = (props) => {
    const { data } = props;
    if (!data?.length > 0) return null;

    return (
      <RowBox sx={{ flexWrap: "wrap" }} fullWidth>
        <Grid container spacing={2}>
          <GridItems xs={12} sm={6} md={4} lg={3}>
            {data?.map((c) => {
              return (
                <CatalogueCard
                  catalogue={c}
                  onEdit={props?.onEdit}
                  onDelete={props?.onDelete}
                  onEnable={props?.onEnable}
                />
              );
            })}
          </GridItems>
        </Grid>
      </RowBox>
    );
  };

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle title="Catalogue " />
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={() => {
          setCatalogue();
          form.reset({ title: null, description: null, doc: null });
          showDialog(true);
        }}
      >
        Add Catalogue
      </Button>

      <Catalogues
        data={data?.results}
        onEdit={editHandler}
        onDelete={deleteHandler}
        onEnable={enableHandler}
      />

      <FormProvider {...form}>
        <AddCatalogueDialog
          show={shouldShowDialog}
          isEdit={catalogue}
          onCancel={() => {
            showDialog(false);
          }}
          onSubmit={form.handleSubmit(submitHandler, errorHandler)}
        />
      </FormProvider>

      <ConfirmDialog
        show={shouldShowConfirm}
        body="Are you sure to delete this catalogue?"
        buttonText="Delete"
        onConfirm={deleteConfirmHandler}
        onCancel={() => showConfirm(false)}
      />
      {(isLoading ||
        addMutation.isLoading ||
        editMutation.isLoading ||
        deleteMutation.isLoading) && <ProgressIndicator />}
    </Box>
  );
}

function canvasToBlob(canvas, mimeType) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("Blob creation failed"));
      }
    }, mimeType);
  });
}
