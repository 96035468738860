import React, { useContext, useState } from "react";

import { Button, Switch } from "@mui/material";

import { Box, Typography, Card, useTheme } from "@mui/material";
import { RowBox, ProgressIndicator } from "../components/ui";
import { PageTitle } from "../components/app-ui";
import {
  OrderSummaryForm,
  OrderProductForm,
  OrderDealerForm,
  OrderActionForm,
} from "../components/forms";

import { useLocation, useNavigate } from "react-router";

//Form Related
import { useForm, FormProvider } from "react-hook-form";

//API related imports
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";
import { getDirtyFields } from "../utils/utils";

import { useAdminOTP } from "../components/hooks";

export default function OrderEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const order = location?.state;

  const queryClient = useQueryClient();
  const { updateOrder } = useContext(APIContext);

  const updateMutation = useMutation((data) => updateOrder(order?.id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["orders"]);
      toast.success("Successfully saved the changes");
    },
  });

  const form = useForm({
    defaultValues: {
      ...order,
      ...order?.dealer,
      phoneNo: [order?.dealer?.phoneNo, order?.dealer?.phoneNo2]
        .filter(Boolean)
        .join("/"),
      ledgerPhone: [order?.dealer?.ledgerPhone1, order?.dealer?.ledgerPhone2]
        .filter(Boolean)
        .join("/"),
    },
  });

  const dirtyFields = form.formState.dirtyFields;
  const submitHandler = () => {
    const values = form.getValues();
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
      updateMutation.mutate(data);
    } else {
      toast.error("No changes to update");
    }
  };

  const [OTPDialog, showOTP] = useAdminOTP(submitHandler);

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle
        title="Add New Product"
        links={[
          { name: "Home", to: "/" },
          { name: "Orders", to: "/orders" },
          { name: "Edit Order Details", to: `/orders/edit/${order?.id}` },
        ]}
        sx={{ mb: 2 }}
      />

      <FormProvider {...form}>
        <Card sx={{ p: 2.5 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Order Details
          </Typography>
          <OrderSummaryForm />
        </Card>

        {order?.items?.map((o, index) => (
          <Card sx={{ p: 2.5, my: 2 }}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              {`Product ${index}`}
            </Typography>
            <OrderProductForm prefix={`items.${index}.`} />
          </Card>
        ))}

        <Card sx={{ p: 2.5, my: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Dealer Details
          </Typography>
          <OrderDealerForm />
        </Card>

        <Card sx={{ p: 2.5, my: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Order Management
          </Typography>
          <OrderActionForm />
        </Card>

        <RowBox sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={{ mr: 1.5 }}
            onClick={form.handleSubmit(showOTP)}
          >
            Save Changes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </RowBox>
      </FormProvider>
      <OTPDialog />

      {updateMutation?.isLoading && <ProgressIndicator />}
    </Box>
  );
}
