import React, { useState } from "react";
import { styled, Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import {
  ProfileImage,
  RowBox,
  DeleteButton,
  EditButton,
  ColBox,
  InputSelect,
} from "./ui";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  activityString,
  capitalize,
  currencyString,
  dateString,
  dateTimeString,
} from "../utils/utils";
import { OrderStatusChip } from "./app-ui";
import { userRole, statusOptions } from "../config";

export const StyledGrid = (props) => {
  const theme = useTheme();
  const { sx, ...params } = props;
  return (
    <Box sx={{ display: "flex", flexGrow: 1, width: "100%" }}>
      <DataGrid
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        hideable={false}
        sx={{
          color: "black",
          fontSize: 13,
          "& .MuiDataGrid-row": {},
          "& .MuiDataGrid-row:hover": {
            backgroundColor: params?.onRowClick ? "none" : "transparent",
          },
          "& .MuiDataGrid-columnHeader": {
            color: theme.palette.secondary.main,
            fontSize: 14,
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: 0,
          },
          "& .MuiDataGrid-columnSeparator--sideRight": {
            display: "none",
          },
          "& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within": {
            outline: 0,
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal",
            color: theme.palette.info.light,
            fontSize: 14,
            py: 2,
          },
          ...sx,
        }}
        {...params}
      />
    </Box>
  );
};

export const SalesTable = (props) => {
  const { data, page, type } = props;
  const theme = useTheme();

  const colProps = {
    sortable: false,
  };

  const rows = data?.results || [];
  const cols = [
    {
      field: "fullname",
      headerName: "Name",
      width: 250,
      renderCell: ({ row }) => (
        <RowBox>
          <ProfileImage image={row?.profileUrl} rounded />
          <Typography variant="body2">{row?.fullname}</Typography>
        </RowBox>
      ),
      ...colProps,
    },
    {
      field: "phoneNo",
      headerName: "Mobile Number",
      width: 150,
      ...colProps,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      ...colProps,
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      ...colProps,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      ...colProps,
      renderCell: ({ row }) => (
        <RowBox>
          <EditButton onClick={() => props?.onEdit(row)} />
          <DeleteButton
            sx={{ ml: 1 }}
            onClick={() => props?.onDelete(row)}
            style="light"
          />
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      autoHeight
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
    />
  );
};

export const OperatorTable = (props) => {
  const { data, page } = props;

  const colProps = {
    sortable: false,
  };

  const rows = data?.results || [];
  const cols = [
    {
      field: "fullname",
      headerName: "Name",
      width: 250,
      renderCell: ({ row }) => (
        <RowBox>
          <ProfileImage image={row?.profileUrl} rounded />
          <Typography variant="body2">{row?.fullname}</Typography>
        </RowBox>
      ),
      ...colProps,
    },
    {
      field: "phoneNo",
      headerName: "Mobile Number",
      width: 150,
      ...colProps,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      ...colProps,
    },
    {
      field: "role",
      headerName: "Role",
      width: 100,
      ...colProps,
      valueFormatter: (param) => capitalize(param?.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      ...colProps,
      renderCell: ({ row }) => (
        <RowBox>
          <Button
            color="secondary"
            variant="text"
            onClick={() => props?.onEdit(row)}
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          <Button
            variant="text"
            color="warning"
            onClick={() => props?.onDelete(row)}
            startIcon={<DeleteOutlineOutlinedIcon color="warning" />}
            sx={{ ml: 1 }}
          >
            Delete
          </Button>
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      autoHeight
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
    />
  );
};

const DealerToolbar = ({ onDelete, selected }) => {
  return (
    selected?.length > 0 && (
      <GridToolbarContainer>
        <Typography sx={{ mx: 2 }}>
          Selected: {selected?.length || 0}
        </Typography>
        <Button variant="contained" onClick={() => onDelete(selected)}>
          Delete
        </Button>
      </GridToolbarContainer>
    )
  );
};

export const DealerTable = (props) => {
  const { data, page } = props;

  const colProps = {
    sortable: false,
  };

  const rows = data?.results || [];
  const cols = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      ...colProps,
    },
    {
      field: "alias",
      headerName: "Id",
      width: 100,
      ...colProps,
    },
    {
      field: "parent",
      headerName: "Parent",
      width: 100,
      ...colProps,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      ...colProps,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      ...colProps,
    },
    {
      field: "state",
      headerName: "State",
      width: 100,
      ...colProps,
    },
    {
      field: "pinCode",
      headerName: "PinCode",
      width: 100,
      ...colProps,
    },
    {
      field: "ledgerPhone",
      headerName: "ledgerPhone",
      width: 200,
      valueGetter: ({ row }) =>
        [row?.ledgerPhone1, row?.ledgerPhone2].filter(Boolean).join("/"),
      ...colProps,
    },
    {
      field: "contact",
      headerName: "Ledger Contact",
      width: 150,
      ...colProps,
    },
    {
      field: "mobilePhone",
      headerName: "Ledger Mobile",
      width: 200,
      valueGetter: ({ row }) =>
        [row?.phoneNo, row?.phoneNo2].filter(Boolean).join("/"),
      ...colProps,
    },
    {
      field: "incomeTaxNo",
      headerName: "Income Tax Number",
      width: 120,
      ...colProps,
    },
    {
      field: "gstNumber",
      headerName: "GST Number",
      width: 120,
      ...colProps,
    },
    {
      field: "tradeDiscount",
      headerName: "Trade Discount %",
      width: 200,
      ...colProps,
      renderCell: ({ row }) => {
        return (
          <ColBox fullWidth>
            {Object.entries(row?.tradeDiscount)?.map(([key, value]) => (
              <RowBox fullWidth>
                <Typography sx={{ flex: 0.7 }}>{key}:</Typography>
                <Typography sx={{ flex: 0.3 }}>{value}%</Typography>
              </RowBox>
            ))}
          </ColBox>
        );
      },
    },
    {
      field: "pricingLevel",
      headerName: "Pricing Level",
      width: 120,
      ...colProps,
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      onRowClick={({ row }) => props?.onEdit(row)}
      checkboxSelection
      onSelectionModelChange={props?.onSelectionModelChange}
      selectionModel={props?.selectionModel}
      keepNonExistentRowsSelected
      components={{
        Toolbar: DealerToolbar,
      }}
      componentsProps={{
        toolbar: {
          onDelete: props?.onDelete,
          selected: props?.selectionModel
        },
      }}
    />
  );
};

const OrderSelect = ({ selected, onUpdate, value, onChange }) => {
  return (
    selected?.length > 0 && (
      <RowBox sx={{ px: 2, py: 1, borderBottom: "1px solid black" }} fullWidth>
        <Typography>Selected: {selected?.length || 0}</Typography>
        <InputSelect
          value={value}
          onChange={onChange}
          placeholder="Select Status"
          options={statusOptions}
          sx={{ width: 200, ml: 2 }}
        />
        {value && (
          <Button variant="contained" sx={{ ml: 2 }} onClick={onUpdate}>
            Update
          </Button>
        )}
      </RowBox>
    )
  );
};

export const OrderTable = (props) => {
  const { data, page } = props;
  const theme = useTheme();

  const colProps = {
    sortable: false,
  };

  const rows = data?.results || [];
  const cols = [
    {
      field: "voucherNo",
      headerName: "Voucher #",
      width: 150,
      ...colProps,
    },
    {
      field: "date",
      headerName: "Order Date",
      width: 150,
      ...colProps,
      valueFormatter: (param) => dateTimeString(param?.value),
    },
    {
      field: "orderNo",
      headerName: "Order #",
      width: 100,
      ...colProps,
    },
    {
      field: "priceLevel",
      headerName: "Price Level",
      width: 100,
      ...colProps,
      valueGetter: ({ row }) => row?.dealer?.pricingLevel,
    },
    {
      field: "name",
      headerName: "Party Name",
      width: 200,
      ...colProps,
      valueGetter: ({ row }) => row?.dealer?.name,
    },
    {
      field: "netPrice",
      headerName: "Amount",
      width: 100,
      ...colProps,
      valueGetter: ({ row }) =>
        `${currencyString(row?.netPrice || row?.price)}`,
    },
    {
      field: "orderBy",
      headerName: "Ordered By",
      width: 200,
      ...colProps,
      renderCell: ({ row }) => (
        <ColBox>
          <Typography variant="body2">
            {row?.orderBy?.name ||
              `${row?.orderBy?.firstname} ${row?.orderBy?.lastname}`}
          </Typography>
          <Typography
            variant="body3"
            style={{ color: theme.palette?.primary?.main }}
          >
            Role: {userRole[row?.orderBy?.role]}{" "}
          </Typography>
        </ColBox>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      ...colProps,
      renderCell: ({ row }) => (
        <OrderStatusChip status={row?.status} sx={{ width: 114 }} />
      ),
    },
    {
      field: "po",
      headerName: "PO",
      width: 120,
      ...colProps,
    },
    {
      field: "notes",
      headerName: "Dispatch Instructions",
      width: 200,
      ...colProps,
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      onRowClick={({ row }) => props?.onEdit(row)}
      checkboxSelection
      onSelectionModelChange={props?.setSelectionModel}
      selectionModel={props?.selectionModel}
      keepNonExistentRowsSelected
      components={{
        Toolbar: OrderSelect,
      }}
      componentsProps={{
        toolbar: {
          selected: props?.selectionModel,
          value: props?.status,
          onChange: props?.setStatus,
          onUpdate: props?.onUpdate,
        },
      }}
    />
  );
};

export const ActivityTable = (props) => {
  const { data, page } = props;

  const colProps = {
    sortable: false,
  };

  const rows = data?.results || [];
  const cols = [
    {
      field: "activity",
      headerName: "Activity",
      width: 500,
      ...colProps,
      renderCell: ({ row }) => <Typography>{activityString(row)}</Typography>,
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      ...colProps,
      valueFormatter: (param) => dateTimeString(param?.value),
    },
  ];

  return (
    <StyledGrid
      autoHeight
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      onRowClick={({ row }) => props?.onView(row)}
    />
  );
};

export const EmployeeTable = (props) => {
  const { data } = props;

  const colProps = {
    sortable: false,
  };

  const rows = data || [];
  const cols = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderCell: ({ row }) => (
        <RowBox>
          <ProfileImage image={row?.profileUrl} rounded />
          <Typography variant="body2">{row?.name}</Typography>
        </RowBox>
      ),
      ...colProps,
    },
    {
      field: "phoneNo",
      headerName: "Mobile Number",
      width: 150,
      ...colProps,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      ...colProps,
    },
  ];

  return (
    <StyledGrid
      autoHeight
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.length || 0}
    />
  );
};
