import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  refreshToken: null,
  productItemsPerPage: 10
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload.access.token;
      state.refreshToken = action.payload.refresh.token;
    },
    logout: (state, action) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
    },
    setProductItemsPerPage: (state, action) => {
      console.log(action.payload)
      state.productItemsPerPage = action.payload
    }
  },
});

export default authSlice.reducer;

export const {
  setUser,
  setToken,
  logout,
  setProductItemsPerPage
} = authSlice.actions;