import * as yup from "yup";

const phoneNoSchema = yup
  .string()
  .trim()
  .matches(/^[0-9]{10}$/, "Enter a valid 10 digit mobile number");

const pincodeSchema = yup
  .string()
  .trim()
  .matches(/^[1-9][0-9]{5}$/, "Enter a valid 6 digit pincode");

const nameSchema = yup
  .string()
  .trim()
  .matches(/^[a-zA-Z -]*$/, "Enter a valid person name");

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  password: yup.string().trim().required("Password is required"),
});

export const changePasswordSchema = yup.object().shape({
  //oldPassword: yup.string().trim().required("Password is required"),
  password: yup.string().trim().required("Password is required"),
  confirmPassword: yup
    .string()
    .trim()
    .required("Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const profileSchema = yup.object().shape({
  firstname: yup.string().trim().required("First Name is required"),
  lastname: yup.string().trim().required("Last Name is required"),
  phoneNo: phoneNoSchema.required("Phone Number is required"),
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  address: yup.string().trim(),
});

export const operatorSchema = yup.object().shape({
  firstname: yup.string().trim().required("First Name is required"),
  lastname: yup.string().trim().required("Last Name is required"),
  phoneNo: phoneNoSchema.required("Phone Number is required"),
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  address: yup.string().trim(),
});

export const salesSchema = yup.object().shape({
  firstname: yup.string().trim().required("First Name is required"),
  lastname: yup.string().trim().required("Last Name is required"),
  phoneNo: phoneNoSchema.required("Phone Number is required"),
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  address: yup.string().trim(),
});

export const catalogueSchema = yup.object().shape({
  title: yup.string().trim().nullable().required("Title is required"),
  description: yup.string().trim().nullable(),
  doc: yup.mixed().required("File is required"),
});

export const offerSchema = yup.object().shape({
  title: yup.string().trim().nullable().required("Title is required"),
  description: yup.string().trim().nullable(),
  image: yup.mixed().required("File is required"),
});

const tradeDiscount = yup.object().shape({
  name: yup.string(),
  value: yup.number()
    .typeError("Enter a valid discount")
    .min(0, "Enter a valid discount %")
    .max(100, "Enter a valid discount %"),
});

// export const dealerSchema = yup.object().shape({
//   name: nameSchema.nullable().required("Name is required"),
//   alias: yup.string().trim().nullable().required("Alias is required"),
//   email: yup
//     .string()
//     .trim()
//     .email("Invalid Email")
//     .required("Email is required"),
//   address: yup.string().trim().nullable().required("Address is required"),
//   state: yup.string().trim().nullable().required("State is required"),
//   pinCode: pincodeSchema.nullable().required("Pincode is required"),
//   parent: yup.string().trim().nullable().required("Parent is required"),
//   ledgerPhone1: yup.string().trim().nullable(),
//   ledgerPhone2: yup.string().trim().nullable(),
//   contact: nameSchema.nullable().required("Contact Name is required"),
//   phoneNo: phoneNoSchema.nullable().required("Phone Number is required"),
//   phoneNo2: phoneNoSchema.nullable(),
//   incomeTaxNo: yup.string().trim().nullable(),
//   gstNumber: yup.string().trim().nullable(),
//   tradeDiscount: tradeDiscount,
//   pricingLevel: yup.string().trim().nullable(),
// });

export const dealerSchema = (categories) => {
  const discountSchema = categories?.reduce((schema, { name }) => {
    schema[name] = yup
      .number()
      .typeError("Enter a valid discount")
      .min(0, "Enter a valid discount %")
      .max(100, "Enter a valid discount %")
      .required("Discount is required")
      return schema
  }, {});

  const yupSchema = yup.object().shape({
    name: yup.string().nullable().required("Name is required"),
    alias: yup.string().trim().nullable().required("Alias is required"),
    email: yup
      .string()
      .trim()
      .email("Invalid Email")
      .required("Email is required"),
    address: yup.string().trim().nullable().required("Address is required"),
    state: yup.string().trim().nullable().required("State is required"),
    pinCode: pincodeSchema.nullable().required("Pincode is required"),
    parent: yup.string().trim().nullable().required("Parent is required"),
    ledgerPhone1: yup.string().trim().nullable(),
    ledgerPhone2: yup.string().trim().nullable(),
    contact: yup.string().nullable().required("Contact Name is required"),
    phoneNo: phoneNoSchema.nullable().required("Phone Number is required"),
    phoneNo2: phoneNoSchema.nullable(),
    incomeTaxNo: yup.string().trim().nullable(),
    gstNumber: yup.string().trim().nullable(),
    pricingLevel: yup.string().trim().nullable(),
    tradeDiscount: yup.object().shape(discountSchema)
  });

  return yupSchema
};

export const productSchema = yup.object().shape({
  type: yup.string().nullable().required("Category is required"),
  name: yup.string().nullable(),
  title: yup.string().trim().nullable(),
  category: yup.string().trim().nullable(),
  catalogueNo: yup
    .string()
    .trim()
    .nullable()
    .required("Catalogue Number is required"),
  price: yup
    .number()
    .typeError("Enter a valid amount")
    .positive("Enter a valid amount")
    .required("Price is required"),
  description: yup.string().trim().nullable(),
});
