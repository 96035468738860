import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import {
  Box,
  Button,
  Card,
  CardContent,
  Pagination,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import {
  RowBox,
  ColBox,
  ProgressIndicator,
  FileButton,
  SearchBar,
  InputSelect,
  GridItems,
} from "../components/ui";
import { PageTitle, ProductCard } from "../components/app-ui";
import { DealerTable } from "../components/tables";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import AddIcon from "@mui/icons-material/Add";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

import { xlsMimeTypes } from "../config";
import { downloadAsFile } from "../utils/utils";
import { OTPDialog } from "../components/dialogs";
import { useCategory } from "../components/hooks";

import { useDispatch, useSelector } from "react-redux";
import { setProductItemsPerPage } from "../store/auth";

const sortOptions = [
  { name: "Ascending Price", value: "price" },
  { name: "Descending Price", value: "-price" },
];

export default function Products() {
  const { productItemsPerPage = 10 } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [q, setQuery] = useState();
  const [type, setType] = useState();
  const [sort, setSortBy] = useState();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const categories = useCategory();

  const queryClient = useQueryClient();
  const { getProducts, bulkUploadProduct, downloadProducts, editProduct } =
    useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["products", { page, limit: productItemsPerPage, q, type, sort }],
    () => getProducts({ page, limit: productItemsPerPage, q, type, sort }),
    {
      select: (data) => data?.data,
      keepPreviousData: true,
    }
  );

  const uploadMutation = useMutation((data) => bulkUploadProduct(data), {
    onSuccess: (data) => {
      const blob = data?.data;
      queryClient.invalidateQueries(["products"]);
      if (blob?.size > 0) {
        toast.error(
          "Product Upload completed with errors. Downloading error report."
        );
        downloadAsFile(data?.data, "Product-BulkUpload-Report.xlsx");
      } else {
        toast.success("Product Upload successfully completed");
      }
    },
  });

  const editMutation = useMutation(({ id, data }) => editProduct(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["products"]);
    },
  });

  const downloadMutation = useMutation(() => downloadProducts(q, type, sort), {
    onSuccess: (data) => {
      const blob = data?.data;
      if (blob?.size > 0) {
        downloadAsFile(data?.data, "Product-Report.xlsx");
        toast.success("Successfully downloaded the products");
      } else {
        toast.error("No Data available to downloaded");
      }
    },
  });

  const addProductHandler = () => {
    navigate(`new`);
  };

  const editHandler = (product) => {
    navigate(`edit/${product.id}`, { state: product });
  };

  const enableHandler = (product) => {
    editMutation.mutate({
      id: product?.id,
      data: {
        isDisabled: !product?.isDisabled,
      },
    });
  };

  const uploadHanlder = (file) => {
    uploadMutation.mutate({ file });
  };

  const downloadHandler = () => {
    downloadMutation.mutate();
  };

  const clearFilters = () => {
    setQuery();
    setType();
    setSortBy();
  };

  return (
    <Box sx={{ py: 2, px: 2 }}>
      <PageTitle title="Products" />

      <Card sx={{ p: 1, my: 1 }}>
        <RowBox>
          <ColBox>
            <Typography variant="h5">Search</Typography>
            <SearchBar
              sx={{ mt: 1, height: 32 }}
              value={q}
              onChange={(q) => {
                setQuery(q);
                setPage(0);
              }}
            />
          </ColBox>
          <ColBox sx={{ ml: 2 }}>
            <Typography variant="h5">Filter</Typography>
            <InputSelect
              options={categories?.map((c) => c.name)}
              placeholder="Select Product Category"
              sx={{ mt: 1, width: 300, height: 32 }}
              value={type}
              onChange={(v) => {
                setType(v);
                setPage(0);
              }}
            />
          </ColBox>
          <ColBox sx={{ ml: 2 }}>
            <Typography variant="h5">Sort By</Typography>
            <InputSelect
              options={sortOptions}
              placeholder="Select Sort Order"
              sx={{ mt: 1, width: 300, height: 32 }}
              value={sort}
              onChange={(v) => {
                setSortBy(v);
                setPage(0);
              }}
            />
          </ColBox>
          <Button
            color="warning"
            sx={{ mt: 2, ml: "auto" }}
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        </RowBox>
      </Card>

      <RowBox sx={{ my: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={addProductHandler}
        >
          Add Product
        </Button>
        <FileButton fileType={xlsMimeTypes} onSelect={uploadHanlder}>
          <Button
            variant="contained"
            startIcon={<CloudUploadOutlinedIcon />}
            sx={{ ml: 1.5 }}
          >
            Bulk Upload
          </Button>
        </FileButton>

        <Button
          variant="contained"
          startIcon={<CloudDownloadOutlinedIcon />}
          sx={{ ml: 1.5 }}
          onClick={downloadHandler}
        >
          Download Product List
        </Button>
      </RowBox>

      <RowBox sx={{ flexWrap: "wrap" }} fullWidth>
        <Grid container spacing={2}>
          <GridItems xs={12} sm={6} md={4} lg={3}>
            {data?.results?.map((p) => {
              return (
                <ProductCard
                  product={p}
                  onEnable={() => enableHandler(p)}
                  onEdit={() => editHandler(p)}
                />
              );
            })}
          </GridItems>
        </Grid>
      </RowBox>

      <RowBox fullWidth center>
        {data?.totalResults > 0 && (
          <Pagination
            count={data?.totalPages}
            shape="rounded"
            variant="outlined"
            page={page}
            onChange={(e, v) => setPage(v)}
            sx={{ my: 2 }}
            color="primary"
          />
        )}
        <Typography sx={{ ml: 1 }}>Items Per Page</Typography>
        <InputSelect
          value={productItemsPerPage.toString()}
          onChange={(v) => {
            dispatch(setProductItemsPerPage(Number.parseInt(v)));
            setPage(1);
          }}
          options={["10", "20", "50", "100"]}
          fullWidth={false}
          sx={{ mx: 1 }}
        />
      </RowBox>

      {(isLoading ||
        editMutation.isLoading ||
        uploadMutation.isLoading ||
        downloadMutation.isLoading) && <ProgressIndicator />}
    </Box>
  );
}
