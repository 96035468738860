import React, { useState, useContext } from "react";

import { Box, Button, Card, Typography } from "@mui/material";
import { CreateDealerCard, PageTitle } from "../components/app-ui";
import { ProgressIndicator, RowBox } from "../components/ui";
import { useLocation, useNavigate } from "react-router";
import { ConfirmDialog } from "../components/dialogs";
import { useAdminOTP, useCategory } from "../components/hooks";
import { DealerForm } from "../components/forms";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dealerSchema } from "../utils/validation";

//API related imports
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";
import { getDirtyFields } from "../utils/utils";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { EmployeeTable } from "../components/tables";

export default function DealerEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const dealer = location?.state;
  const categories = useCategory();

  const [shouldShowConfirm, showConfirm] = useState(false);

  const queryClient = useQueryClient();
  const { updateUser, deleteUser } = useContext(APIContext);

  const updateMutation = useMutation((data) => updateUser(dealer?.id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", { role: "dealer" }]);
      toast.success("Successfully saved the changes");
      navigate(-1);
    },
  });

  const deleteMutation = useMutation(() => deleteUser(dealer?.id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", { role: "dealer" }]);
      toast.success("Successfully deleted the dealer");
      navigate(-1);
    },
  });

  const form = useForm({
    resolver: yupResolver(dealerSchema(categories)),
    defaultValues: dealer,
  });

  const dirtyFields = form.formState.dirtyFields;
  const submitHandler = () => {
    const values = form.getValues();
    console.log(values);
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
      updateMutation.mutate(data);
    } else {
      toast.error("No changes to update");
    }
  };

  const [OTPDialog, showOTP] = useAdminOTP(submitHandler);

  const errorHandler = (error) => {
    console.log(error);
  };

  const deleteHandler = () => {
    deleteMutation.mutate();
  };

  const cancelHandler = () => {
    navigate(-1);
  };

  const [DeleteOTPDialog, showDeleteOTP] = useAdminOTP(deleteHandler);

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle
        title="Add New Dealer"
        links={[
          { name: "Home", to: "/" },
          { name: "Dealers", to: "/dealers" },
          { name: "Edit Dealer", to: `/dealers/edit/${dealer.id}` },
        ]}
        sx={{ mb: 2 }}
      />

      <RowBox fullWidth>
        <Button
          variant="contained"
          color="warning"
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={() => showConfirm(true)}
          sx={{ ml: "auto", mb: 2 }}
        >
          Delete this Dealer
        </Button>
      </RowBox>

      <FormProvider {...form}>
        <DealerForm />

        <Card sx={{ p: 2.5, mb: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Employees
          </Typography>
          <EmployeeTable data={dealer?.employees} />
        </Card>

        <RowBox fullWidth sx={{ mt: 4 }} center>
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelHandler}
            sx={{ mr: 1.5 }}
          >
            Cancel
          </Button>
          <Button variant="contained" sx={{ ml: 1 }} onClick={form.handleSubmit(showOTP)}>
            Submit Changes
          </Button>
        </RowBox>
      </FormProvider>

      <OTPDialog />
      <DeleteOTPDialog />

      <ConfirmDialog
        show={shouldShowConfirm}
        body={`Are you sure to delete ${dealer?.name} ?`}
        buttonText="Delete"
        onConfirm={showDeleteOTP}
        onCancel={() => showConfirm(false)}
      />
      {(updateMutation.isLoading || deleteMutation.isLoading) && (
        <ProgressIndicator />
      )}
    </Box>
  );
}
