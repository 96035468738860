import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { Box, Button, Card, CardContent } from "@mui/material";
import { PageTitle } from "../components/app-ui";
import { ConfirmDialog } from "../components/dialogs";

import AddIcon from "@mui/icons-material/Add";
import { AddSalesDialog } from "../components/dialogs";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { salesSchema } from "../utils/validation";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { ProgressIndicator } from "../components/ui";
import { toast } from "react-toastify";
import { SalesTable } from "../components/tables";

import { useAdminOTP } from "../components/hooks";

export default function SalesList() {
  const [page, setPage] = useState(0);
  const [shouldShowDialog, showDialog] = useState(false);
  const [shouldShowConfirm, showConfirm] = useState(false);
  const [selectedUser, setUser] = useState();
  const [deleteMessage, setDeleteMessage] = useState();
  const navigate = useNavigate()

  const queryClient = useQueryClient();
  const { addUser, getSalesExecutives, deleteUser } = useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["user", { role: "sales", page }],
    () => getSalesExecutives(page),
    {
      select: (data) => data?.data,
      keepPreviousData: true,
    }
  );

  const addSalesMutation = useMutation((data) => addUser(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", { role: "sales"}]);
      showDialog(false);
      toast.success("Successfully added marketing coordinator");
    },
  });

  const deleteMutation = useMutation((id) => deleteUser(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", {role: "sales"}]);
      setUser(null);
      toast.success("Successfully deleted marketing coordinator");
    },
  });

  const salesForm = useForm({
    resolver: yupResolver(salesSchema),
  });

  const submitHandler = () => {
    const values = salesForm.getValues()
    addSalesMutation.mutate({ ...values, role: "sales" });
  };

  const [OTPDialog, showOTP] = useAdminOTP(submitHandler)

  const editHandler = (user) => {
    navigate(`edit/${user.id}`, { state: user });
  };

  const deleteHandler = () => {
    setDeleteMessage(`Are you sure to delete user ${selectedUser?.fullname}?`);
    showConfirm(true);
  };

  const [DeleteOTPDialog, showDeleteOTP] = useAdminOTP(deleteHandler)

  const deleteSetupHandler = (user) => {
    setUser(user)
    showDeleteOTP()
  }

  const deleteConfirmHandler = () => {
    showConfirm(false);
    deleteMutation.mutate(selectedUser.id);
  };

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle title="Marketing Coordinator" />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={() => showDialog(true)}
      >
        Add Marketing Coordinator
      </Button>

      <Card sx={{mb: 0}}>
        <CardContent sx={{p: 0, ":last-child": { pb: 0}}}>
          <SalesTable
            data={data}
            page={page}
            onPageChange={setPage}
            onEdit={editHandler}
            onDelete={deleteSetupHandler}
          />
        </CardContent>
      </Card>

      <FormProvider {...salesForm}>
        <AddSalesDialog
          show={shouldShowDialog}
          onCancel={() => showDialog(false)}
          onSubmit={salesForm.handleSubmit(showOTP)}
        />
      </FormProvider>
      <OTPDialog />
      <DeleteOTPDialog />

      <ConfirmDialog
        show={shouldShowConfirm}
        body={deleteMessage}
        buttonText="Delete"
        onConfirm={deleteConfirmHandler}
        onCancel={() => showConfirm(false)}
      />
      {addSalesMutation.isLoading && <ProgressIndicator />}
    </Box>
  );
}
