import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Box,
  Select,
  MenuItem,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogContent,
  CircularProgress,
  useTheme,
  styled,
  ImageList,
  ImageListItem,
  DialogActions,
  InputBase,
  Grid,
} from "@mui/material";

import {
  LocalizationProvider,
  DesktopDatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Carousel from "react-material-ui-carousel";
import { FileUploader } from "react-drag-drop-files";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "../utils/canvasPreview";
import "react-image-crop/dist/ReactCrop.css";

import OtpInput from "react-otp-input";

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
//import { Document, Page } from "react-pdf";
//import { pdfjs } from 'react-pdf';

import { Controller, set, useFormContext } from "react-hook-form";

import { getMimeType, getStatesOfCountry } from "../utils/utils";

import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Dayjs } from "dayjs";

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

export const RowBox = (props) => {
  const { sx, center, fullWidth, ...params } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: props?.center ? "center" : "flex-start",
        alignItems: "center",
        position: props?.relative ? "relative" : "inherit",
        width: fullWidth ? "100%" : "auto",
        ...props?.sx,
      }}
      {...params}
    >
      {props?.children}
    </Box>
  );
};

export const ColBox = (props) => {
  const { sx, center, fullWidth, ...params } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: fullWidth ? "100%" : "auto",
        alignItems: center ? "center" : "flex-start",
        ...sx,
      }}
      {...params}
    >
      {props?.children}
    </Box>
  );
};

export const ImageBox = (props) => {
  return <Box component="img" {...props} />;
};

export const GridItems = (props) => {
  const { children, ...params } = props;
  return children?.map((c) => (
    <Grid item {...params}>
      {c}
    </Grid>
  ));
};

export const InputField = (props) => {
  const { control, defaultValue } = useFormContext();
  const { title, name, wsx, sx, titleVariant, ...params } = props;
  return (
    <ColBox sx={{ width: "100%", ...props?.wsx }}>
      {title && (
        <Typography sx={{ mb: 0.75 }} variant={titleVariant}>
          {title}
        </Typography>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            value={value}
            onChange={onChange}
            error={error}
            helperText={error?.message}
            autoComplete="off"
            sx={sx}
            {...params}
          />
        )}
      />
    </ColBox>
  );
};

export const DateController = (props) => {
  const { control, defaultValue } = useFormContext();
  const { title, name, wsx, sx, titleVariant, ...params } = props;
  console.log("Default ", defaultValue);
  return (
    <ColBox sx={{ width: "100%", ...props?.wsx }}>
      {title && (
        <Typography sx={{ mb: 0.75 }} variant={titleVariant}>
          {title}
        </Typography>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={value || null}
              onChange={onChange}
              renderInput={(params) => <TextField {...params} />}
              sx={sx}
              {...params}
            />
            <Error error={error} />
          </LocalizationProvider>
        )}
      />
    </ColBox>
  );
};

// export const DatePicker = (props) => {
//   return (
//     <TextField
//       value={props?.value}
//       onChange={(e) => props?.onChange(e.target.value)}
//       type={props?.type || "datetime-local"}
//       sx={{
//         "& .MuiInputBase-input": {
//           padding: "12px",
//         },
//       }}
//     />
//   );
// };

export const DatePicker = (props) => {
  const { sx, placeholder, value, onChange, ...settings } = props;
  return (
    <DateTimePicker
      value={value}
      onChange={(date) => onChange(date.toDate())}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder: placeholder,
          }}
        />
      )}
      sx={sx}
      {...settings}
    />
  );
};

export const DatePickerController = (props) => {
  const { control, defaultValue } = useFormContext();
  const { title, name, wsx, sx, titleVariant, type,  placeholder, ...params } = props;
  console.log("Default", defaultValue)
  return (
    <ColBox sx={{ width: "100%", ...props?.wsx }}>
      {title && (
        <Typography sx={{ mb: 0.75 }} variant={titleVariant}>
          {title}
        </Typography>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          console.log(value);
          return <DatePicker value={value} onChange={onChange} type={type} placeholder={placeholder} />;
        }}
      />
    </ColBox>
  );
};

export const ProgressIndicator = (props) => {
  return (
    <Box boxShadow={4} elevation={2}>
      <Dialog
        open={true}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export const ProfileImage = (props) => {
  //TODO: Default Image, different sizes
  const theme = useTheme();
  const variant = props?.variant || "default";
  const profile = require("../assets/icons/default-user.jpg");
  const plus = require("../assets/icons/plus-circle.png");

  const height = {
    header: 30,
    profile: 128,
    default: 40,
  };

  const width = {
    header: 30,
    profile: 128,
    default: 40,
  };

  const imgWidth = width[variant];
  const imgHeight = height[variant];

  const { image } = props;
  const url = image
    ? image instanceof File
      ? URL.createObjectURL(image)
      : typeof image === "object"
      ? image.url
      : image
    : profile;

  return (
    <Box sx={{ position: "relative" }}>
      <ImageBox
        src={url}
        sx={{
          height: imgHeight,
          width: imgWidth,
          mr: 1,
          border: `1px solid ${theme.palette.background.main}`,
          borderRadius: props?.rounded ? imgHeight / 2 : 1,
        }}
        onClick={props?.onClick}
      />
      {props?.editable && (
        <ImageBox
          src={plus}
          sx={{
            width: imgWidth / 4,
            height: imgHeight / 4,
            position: "absolute",
            zIndex: 2,
            bottom: imgWidth / 8,
            right: imgWidth / 8,
          }}
        />
      )}
    </Box>
  );
};

export const SearchBar = (props) => {
  const theme = useTheme();
  return (
    <RowBox
      sx={{
        height: 50,
        borderRadius: 2,
        border: `1px solid ${theme.palette.border.main}`,
        justifyContent: "flex-start",
        width: 300,
        ...props?.sx,
      }}
    >
      <SearchIcon color="secondary" sx={{ mx: 1, fontSize: 28 }} />
      <InputBase
        sx={{ pr: 1, mr: "auto" }}
        placeholder={props?.placeholder || "Search..."}
        value={props?.value}
        onChange={(e) => props?.onChange(e.target.value)}
      />
      {props?.value?.length > 0 && (
        <ClearIcon
          color="info"
          sx={{ mr: 1, width: 20, height: 20 }}
          onClick={() => props?.onChange("")}
        />
      )}
    </RowBox>
  );
};

export const ImageCarousel = (props) => {
  const StyledImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    objectFit: props?.objectFit || "contain",
  }));

  if (!props?.images || props?.images?.length === 0) return null;

  return (
    <Carousel
      height={280}
      sx={props?.sx}
      index={props?.last ? props?.images?.length - 1 : 0}
      autoPlay={false}
      indicators={props?.images?.length > 1}
    >
      {props?.images?.map((i, index) => {
        const objectType = typeof i;
        const url =
          i instanceof File
            ? URL.createObjectURL(i)
            : objectType === "object"
            ? i.url
            : i;
        return <StyledImage key={index} src={url} />;
      })}
    </Carousel>
  );
};

export const ImageGrid = (props) => {
  const StyledImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    objectFit: props?.objectFit || "cover",
    border: `1px solid ${theme.palette.info.light}`,
    boxSizing: "border-box",
  }));

  const Delete = styled(ImageBox)(() => ({
    position: "absolute",
    right: 0,
    top: 0,
  }));

  if (!props?.images || props?.images?.length === 0) return null;

  return (
    <ImageList cols={3} sx={props?.sx}>
      {props?.images?.map((i, index) => {
        const objectType = typeof i;
        const url =
          i instanceof File
            ? URL.createObjectURL(i)
            : objectType === "object"
            ? i.url
            : i;
        return (
          <ImageListItem>
            <StyledImage key={index} src={url} />
            {props?.editable && (
              <Delete
                src={require("../assets/icons/cancel.png")}
                onClick={() => props?.onDelete(index)}
              />
            )}
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export const Error = (props) => {
  const theme = useTheme();
  const { error } = props;
  if (!error) return null;
  return (
    <Typography
      variant="error"
      sx={{ ml: theme.typography.pxToRem(14), mt: theme.typography.pxToRem(3) }}
    >
      {error?.message}
    </Typography>
  );
};

export const FileButton = (props) => {
  const ref = useRef();

  const buttonClickHandler = () => {
    ref.current.click();
  };

  const fileSelectHandler = (e) => {
    const file = e.target.files[0];
    props?.onSelect(file);
    e.target.value = "";
  };

  return (
    <Box sx={props?.sx}>
      <input
        ref={ref}
        type="file"
        hidden
        accept={props?.fileType || "image/*"}
        onChange={fileSelectHandler}
      ></input>
      {React.cloneElement(props.children, {
        onClick: buttonClickHandler,
      })}
    </Box>
  );
};

export const FileSelect = (props) => {
  const { control, defaultValue } = useFormContext();

  const selectHandler = (image, prev, callback) => {
    if (!image) {
      return;
    }

    if (props?.single) {
      callback(image);
      return;
    }

    if (!prev || prev.length === 0) {
      callback([image]);
      return;
    }

    callback([...prev, image]);
  };

  return (
    <ColBox sx={props?.wsx}>
      {props?.title && (
        <RowBox sx={{ mb: 1.25 }}>
          <Typography variant="h3Bold">{props.title}</Typography>
          {props.subtitle && (
            <Typography variant="h4" sx={{ ml: 1 }}>
              {props?.subtitle}
            </Typography>
          )}
        </RowBox>
      )}
      <Controller
        control={control}
        name={props?.name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box sx={{}}>
            {value && props?.show && (
              <ImageCarousel
                last
                images={props?.single ? [value] : value}
                sx={{ mb: 2 }}
              />
            )}
            {value && props?.showGrid && (
              <ImageGrid
                images={props?.single ? [value] : value}
                sx={{ mb: 2 }}
              />
            )}
            <FileButton
              value={value}
              onSelect={(image) => selectHandler(image, value, onChange)}
            >
              {props.children}
            </FileButton>
            {props?.showError && <Error error={error} />}
          </Box>
        )}
      />
    </ColBox>
  );
};

const DropArea = styled(ColBox)(({ theme }) => ({
  paddingTop: 30,
  paddingBottom: 30,
  borderWidth: 1,
  borderStyle: "dashed",
  borderColor: theme.palette.border,
}));

export const FileDrop = (props) => {
  const theme = useTheme();
  const { value, fileType, buttonText } = props;

  const fileTypes = {
    image: ["PNG", "JPG", "JPEG"],
    doc: ["PDF"],
    all: ["PNG", "JPG", "JPEG", "PDF"],
  };

  return (
    <Box sx={{ width: "100%", ...props?.sx }}>
      <FileUploader
        handleChange={props?.onChange}
        name="file"
        types={fileTypes[fileType]}
        classes="drop-area"
      >
        <DropArea fullWidth center>
          <RowBox>
            <ColBox
              center
              sx={{
                borderWidth: 1,
                borderColor: theme.palette.border.main,
                borderStyle: "solid",
                mr: 1,
              }}
            >
              {value && getMimeType(value) === "application/pdf" && (
                <PDFThumbbnail pdf={value} width={102} />
              )}
              {value && getMimeType(value).startsWith("image/") && (
                <ImageBox
                  src={
                    value instanceof File ? URL.createObjectURL(value) : value
                  }
                  width={102}
                  height="auto"
                  maxHeight={160}
                />
              )}
            </ColBox>

            <InsertPhotoOutlinedIcon
              sx={{ fontSize: 32, color: theme.palette.info.light }}
            />
            <Typography
              variant="body1"
              color="primary"
              sx={{ cursor: "pointer", mx: 0.5 }}
            >
              {buttonText || "Upload a Photo"}
            </Typography>
            <Typography variant="body1" color="secorday">
              or drag and drop
            </Typography>
          </RowBox>
        </DropArea>
      </FileUploader>
    </Box>
  );
};

export const FileDropController = (props) => {
  const { control, defaultValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={props?.name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ColBox fullWidth sx={props?.sx}>
          <FileDrop
            value={value}
            onChange={onChange}
            fileType={props.fileType}
            buttonText={props.buttonText}
          ></FileDrop>
          {error && <Error error={error} />}
        </ColBox>
      )}
    />
  );
};

export const ImageCropDialog = (props) => {
  const { image: srcImage } = props;
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef();
  const canvasRef = useRef();
  const scale = 1;
  const aspect = 1;
  const rotate = 1;

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop({ unit: "%", width: 50 }, aspect, mediaWidth, mediaHeight),
      mediaWidth,
      mediaHeight
    );
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    } else {
      setCrop({
        unit: "%", // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 50,
        height: 50,
      });
    }
  };

  const useDebounceEffect = (fn, waitTime, deps) => {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps);
      }, waitTime);

      return () => {
        clearTimeout(t);
      };
    }, deps);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        canvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          canvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const cropHandler = () => {
    canvasRef.current.toBlob((blob) => {
      const file = new File([blob], "profile.png", { type: blob.type });
      props?.onCrop?.(file);
      setCompletedCrop(null);
      setCrop(null);
    });
  };

  return (
    <Dialog open={props?.show} scroll="body">
      <DialogContent>
        <ReactCrop
          crop={crop}
          onChange={setCrop}
          onComplete={setCompletedCrop}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            src={props?.image}
            style={{ width: "100%", height: "100%", maxHeight: "80vh" }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
        {completedCrop && (
          <canvas
            ref={canvasRef}
            style={{
              display: "none",
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cropHandler}>Crop</Button>
        <Button onClick={props?.onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export const InputSelect = (props) => {
  const { value, onChange, placeholder, options, ...settings } = props;
  const _options = options?.map((o) => {
    return typeof o === "object" ? o : { name: o, value: o };
  });

  return (
    <Select
      value={value}
      onChange={(e) => {console.log(e.target.value); onChange(e.target.value)}}
      fullWidth
      displayEmpty
      renderValue={(v) => {
        if (!value && placeholder) {
          return <Typography variant="body2">{placeholder}</Typography>;
        }
        const name = _options?.find((o) => o.value === value)?.name;
        return name;
      }}
      {...settings}
    >
      {placeholder && (
        <MenuItem key="empty" value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {_options?.map((o) => (
        <MenuItem key={o.name} value={o.value} onClick={() => onChange(o.value)}>
          {o.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export const SelectController = (props) => {
  const theme = useTheme();
  const { control, defaultValue } = useFormContext();
  const {
    wsx,
    title,
    name,
    placeholder,
    options,
    hideError,
    fullWidth,
    titleVariant,
    ...settings
  } = props;

  return (
    <ColBox sx={wsx} fullWidth={fullWidth}>
      {title && (
        <Typography variant={titleVariant || "h3Bold"} sx={{ mb: 0.75 }}>
          {title}
        </Typography>
      )}

      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <InputSelect
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              options={options}
              {...settings}
            />
            {!hideError && <Error error={error} />}
          </>
        )}
      />
    </ColBox>
  );
};

export const WrappedText = (props) => {
  const { sx, noofLines, ...params } = props;
  return (
    <Typography
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: noofLines || "2",
        WebkitBoxOrient: "vertical",
        ...sx,
      }}
      style={{ whiteSpace: "pre-wrap" }}
      {...params}
    >
      {props?.children}
    </Typography>
  );
};

export const EditButton = (props) => {
  const theme = useTheme();
  const { style, sx, ...params } = props;
  return (
    <Button
      sx={{
        backgroundColor: theme.palette.primary.main,
        minWidth: 0,
        ":hover": {
          backgroundColor: theme?.palette.primary.dark,
        },
        ...sx,
      }}
      {...params}
    >
      <EditOutlinedIcon fontSize="small" sx={{ color: "white" }} />
    </Button>
  );
};

export const DeleteButton = (props) => {
  const theme = useTheme();
  const { style, sx, ...params } = props;
  return (
    <Button
      sx={{
        backgroundColor: theme.palette.warning.main,
        minWidth: 0,
        ":hover": {
          backgroundColor: theme?.palette.warning.dark,
        },
        ...sx,
      }}
      {...params}
    >
      <DeleteOutlineOutlinedIcon fontSize="small" sx={{ color: "white" }} />
    </Button>
  );
};

export const PDFThumbbnail = (props) => {
  return (
    <Document file={props?.pdf}>
      <Page
        pageNumber={1}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        width={props?.width}
      />
    </Document>
  );
};

export const StatePicker = (props) => {
  const [stateList, setStateList] = useState([]);
  const { country, sx, ...params } = props;

  let states = [];

  if (country) {
    states = getStatesOfCountry(country);
    if (!states || !states.length) {
      states = [country];
    }
  }

  const _states = states.map((s) => ({ name: s, value: s }));
  return (
    <SelectController
      name="state"
      options={_states}
      placeholder="Select State"
      {...params}
    />
  );
};

export const OTPInputView = (props) => {
  return (
    <OtpInput
      value={props?.value}
      onChange={props?.onChange}
      numInputs={4}
      inputStyle={{
        margin: 12,
        width: 72,
        height: 72,
        fontSize: 25,
        borderColor: "#EAF0F6",
        borderWidth: 2,
        borderRadius: 5,
        borderStyle: "solid",
        color: "#2C3E50",
      }}
      focusStyle={{
        outline: "none",
        borderColor: "#5F7388",
      }}
    />
  );
};
