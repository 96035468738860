import React, { useContext, useState } from "react";

import { Button, Switch } from "@mui/material";

import { Box, Typography, Card, useTheme } from "@mui/material";
import { PageTitle, ProductImageController } from "../components/app-ui";

import { RowBox, ProgressIndicator } from "../components/ui";
import {
  BathWareProductForm,
  FaucetProductForm,
  KitchenProductForm,
  ProductInfoForm,
  SanitaryProductForm,
} from "../components/forms";

import { useLocation, useNavigate } from "react-router";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema } from "../utils/validation";

//API related imports
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { getDirtyFields } from "../utils/utils";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ConstructionOutlined } from "@mui/icons-material";
import { useAdminOTP } from "../components/hooks";

export default function ProductEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const product = location?.state;

  console.log("product ", product)

  const [shouldShowConfirm, showConfirm] = useState(false);

  const queryClient = useQueryClient();
  const { createProduct, deleteProduct, editProduct } = useContext(APIContext);

  const editMutation = useMutation((data) => editProduct(product?.id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["products"]);
      toast.success("Successfully saved the changes");
      navigate(-1);
    },
  });

  const form = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: product,
  });

  const type = form.watch("type");

  const productForm = {
    "Faucet": <FaucetProductForm />,
    "Bath Ware": <BathWareProductForm />,
    "Sanitary Ware": <SanitaryProductForm />,
    "Kitchen Sink": <KitchenProductForm />,
    "Mirror": <FaucetProductForm />,
    "Nobero": <FaucetProductForm />,
    "PTMT": <FaucetProductForm />,
    "Water Heater": <FaucetProductForm />
  };

  const dirtyFields = form.formState.dirtyFields;
  const submitHandler = () => {
    const values = form.getValues()
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
      editMutation.mutate(data);
    } else {
      toast.error("No changes to save");
    }
  };

  const [OTPDialog, showOTP] = useAdminOTP(submitHandler)
  const errorHandler = (error) => {
    console.log(error);
  };

  const enableHandler = () => {
    editMutation.mutate({
      images: product.images, //Always send images
      isDisabled: !product?.isDisabled,
    });
  };
  const [EnableOTPDialog, showEnableOTP] = useAdminOTP(enableHandler)

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle
        title="Add New Product"
        links={[
          { name: "Home", to: "/" },
          { name: "Products", to: "/products" },
          { name: "Edit Product", to: `/products/edit/${product?.id}` },
        ]}
        sx={{ mb: 2 }}
      />

      <FormProvider {...form}>
        {/* <Card sx={{ p: 2.5 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Product Images
          </Typography>
          <ProductImageController
            name="images"
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "60%",
              },
            }}
          />
        </Card> */}
        <Card sx={{ p: 2.5, mt: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Product Details
          </Typography>
          <ProductInfoForm />
        </Card>

        {type && (
          <Card sx={{ p: 2.5, mt: 2 }}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              Product Information
            </Typography>
            {productForm[type]}
          </Card>
        )}

        <Card sx={{ p: 2.5, mt: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Product Management
          </Typography>

          <Typography variant="h5">Product Status</Typography>
          <RowBox>
            <Switch
              color="primary"
              checked={!product?.isDisabled}
              onChange={showEnableOTP}
            />
            <Typography variant="body1">
              {product?.isDisabled ? "Disabled" : "Active"}
            </Typography>
          </RowBox>
        </Card>

        <RowBox sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={{ mr: 1.5 }}
            onClick={form.handleSubmit(showOTP)}
          >
            Save Changes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </RowBox>
      </FormProvider>
      <OTPDialog />
      <EnableOTPDialog />

      {/* <ConfirmDialog
        show={shouldShowConfirm}
        body={`Are you sure to delete this product?`}
        buttonText="Delete"
        onConfirm={deleteHandler}
        onCancel={() => showConfirm(false)}
      /> */}

      {editMutation.isLoading && <ProgressIndicator />}
    </Box>
  );
}
