export const xlsMimeTypes =
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const userRole = {
  sales: "Marketing Coordinator",
  dealer: "Dealer",
  employee: "Dealer Employee",
  sales: "Marketing Coordinator",
};

export const statusOptions = [
  { name: "Pending", value: "pending" },
  { name: "Processing", value: "processing" },
  { name: "Completed", value: "completed" },
  { name: "Rejected", value: "rejected" },
  { name: "Deleted", value: "deleted" },
];

export const categories = [
  "Faucet",
  "Bath Ware",
  "Sanitary Ware",
  "Kitchen Sink",
  "Mirror",
  "Nobero",
  "PTMT",
  "Water Heater",
];
