import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, styled, Typography, useTheme } from "@mui/material";
import { RowBox, ImageBox, ColBox, ProgressIndicator } from "../components/ui";
import { LoginForm } from "../components/forms";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../utils/validation";

//API related imports
import { useMutation } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

//Store related imports
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../store/auth";

export default function Login() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(loginSchema),
  });

  const { login } = useContext(APIContext);
  const loginMutation = useMutation((data) => login(data), {
    onSuccess: (data) => {
      dispatch(setToken(data?.data?.tokens));
      dispatch(setUser(data?.data?.user));
      navigate("/", { replace: true });
    },
  });

  const submitHandler = (values) => {
    loginMutation.mutate(values);
  };

  const Back = styled("div")(() => ({
    height: "100%",
    width: "100%",
    display: "flex",
    flex: 1,
  }));

  const Form = styled(ColBox)(({ theme }) => ({
    background: "white",
    zIndex: 3,
    padding: "50px 50px",
    borderRadius: "8px",
    boxShadow: `0px 0px 40px 0px ${theme.palette.shadow.main}`,
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: 420,
    },
  }));

  return (
    <RowBox sx={{ minHeight: "100vh" }} center>
      <RowBox fullWidth sx={{ height: "100vh", position: "absolute" }}>
        <Back sx={{ backgroundColor: theme.palette.primary.main }} />
        <Back sx={{ backgroundColor: theme.palette.background.main }} />
      </RowBox>
      <Form>
        <ImageBox
          src={require("../assets/logo.png")}
          sx={{ width: 112, height: "auto", mb: 3.5, alignSelf: "center" }}
        />
        <Typography variant="h1" color="primary">
          Welcome!
        </Typography>
        <Typography variant="h3" sx={{ mb: 3.8, mt: 0.8 }}>
          SIGN INTO YOUR ACCOUNT
        </Typography>
        <FormProvider {...methods}>
          <LoginForm sx={{ width: "100%" }} />
        </FormProvider>
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          onClick={methods.handleSubmit(submitHandler)}
        >
          Login
        </Button>
      </Form>
      {loginMutation.isLoading && <ProgressIndicator />}
    </RowBox>
  );
}
