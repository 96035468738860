import React, { useState, useContext } from "react";

import { Box, Grid } from "@mui/material";
import {
  EditPasswordCard,
  PageTitle,
  EditProfileCard,
} from "../components/app-ui";
import { ImageCropDialog } from "../components/ui";

import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router";

import { getDirtyFields } from "../utils/utils";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordSchema, profileSchema } from "../utils/validation";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { ProgressIndicator } from "../components/ui";
import { toast } from "react-toastify";
import { setUser } from "../store/auth";

export default function EditProfile() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()

  const [shouldShowCrop, showCrop] = useState(false);
  const [imageToCrop, setImageToCrop] = useState();

  const queryClient = useQueryClient();
  const { updateProfile } = useContext(APIContext);

  const updateProfileMutation = useMutation((data) => updateProfile(data), {
    onSuccess: (data) => {
      dispatch(setUser(data?.data));
      toast.success("Successfully saved the changes");
    },
  });

  const passwordForm = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const profileForm = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: user,
  });

  const dirtyFields = profileForm.formState.dirtyFields;
  const profileChangeHandler = (values) => {
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
      updateProfileMutation.mutate(data);
    } else {
      toast.error("No changes to update");
    }
  };

  const passwordChangeHandler = (values) => {
    const data = { password: values?.password };
    updateProfileMutation.mutate(data);
  };

  const profileCancelHandler = () => {
    profileForm.reset(user);
    navigate(-1)
  };

  const passwordCancelHandler = () => {
    passwordForm.reset({
      password: "",
      confirmPassword: "",
    });
    navigate(-1)
  };

  const imageSelectHandler = (image) => {
    setImageToCrop(image);
    showCrop(true);
  };

  const cropCompleteHandler = (image) => {
    showCrop(false);
    updateProfileMutation.mutate({
      profile: image,
    });
  };

  const profileImgDeleteHandler = () => {
    updateProfileMutation.mutate({
      profileUrl: "",
    });
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
      <Grid item xs={12}>
        <PageTitle
          title="Edit Profile"
          links={[
            { name: "Home", to: "/" },
            { name: "Profile", to: "/profile" },
            { name: "Edit Profile", to: "/profile/edit" },
          ]}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <FormProvider {...passwordForm}>
          <EditPasswordCard
            profileImage={user?.profileUrl}
            onCancel={passwordCancelHandler}
            onEdit={passwordForm.handleSubmit(passwordChangeHandler)}
            onProfileChange={imageSelectHandler}
            onProfileDelete={profileImgDeleteHandler}
          />
        </FormProvider>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormProvider {...profileForm}>
          <EditProfileCard
            onCancel={profileCancelHandler}
            onEdit={profileForm.handleSubmit(profileChangeHandler)}
          />
        </FormProvider>
      </Grid>
      <ImageCropDialog
        image={
          imageToCrop instanceof File
            ? URL.createObjectURL(imageToCrop)
            : imageToCrop
        }
        show={shouldShowCrop}
        onCrop={cropCompleteHandler}
        onCancel={() => showCrop(false)}
      />
      {updateProfileMutation.isLoading && <ProgressIndicator />}
    </Grid>
  );
}
