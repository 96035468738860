import { BorderBottom } from "@mui/icons-material";
import { createTheme } from "@mui/material";
import { getDefaultNormalizer } from "@testing-library/react";

const colorTheme = createTheme({
  palette: {
    primary: {
      dark: "#c86200",
      main: "#EA7300",
      light: "#d86a00",
      lightest: "#EA73001A"
    },
    secondary: {
      main: "#5F5953",
      dark: "#56504b",
    },
    info: {
      main: "#53545E",
      light: "#A7A5A3",
    },
    background: {
      main: "#E5E5E5",
      dark: "#b9abaa"
    },
    shadow: {
      main: "#33636470",
    },
    warning: {
      dark: "#E45669",
      main: "#FD6074",
      light: "#FD60741A"
    },
    success: {
      main: "#3AAC29",
    },
    error: {
      main: "#d32f2f",
    },
    neutral: {
      main: '#fff'
    },
    border: {
      main: '#E8E8F7'
    },
    lightPrimary: {
      main: "#EA73001A"
    }
  },
});

let theme = createTheme();
theme = createTheme({
  palette: colorTheme.palette,
  spacing: 10,
  typography: {
    fontFamily: "Poppins",
    fontWeight: "normal",
    h1: {
      fontWeight: 600,
      fontSize: 27,
      color: colorTheme.palette.info.main,
    },
    h2: {
      fontWeight: 600,
      fontSize: 18,
      color: colorTheme.palette.secondary.main,
    },
    h3: {
      fontWeight: 500,
      fontSize: 20,
      color: colorTheme.palette.info.main,
    },
    h4: {
      fontWeight: 600,
      fontSize: 16,
      color: colorTheme.palette.secondary.main,
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      color: colorTheme.palette.secondary.main,
    },
    body1: {
      fontWeight: "normal",
      fontSize: 16,
      color: colorTheme.palette.info.main,
    },
    body2: {
      fontWeight: "normal",
      fontSize: 14,
      color: colorTheme.palette.info.light,
    },
    body3: {
      fontWeight: "normal",
      fontSize: 14,
      color: colorTheme.palette.info.main,
    },
    subtitle1: {
      fontWeight: "normal",
      fontSize: 16,
      color: colorTheme.palette.info.light,
    },
    subtitle2: {
      fontWeight: "normal",
      fontSize: 12,
      color: colorTheme.palette.info.light,
    },
    error: {
      fontWeight: "normal",
      fontSize: 12,
      color: colorTheme.palette.error.main,
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variant: "body1",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullWidth: true,
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: 6,
              },
            },
            "& .MuiInputBase-input": {
              fontSize: 16,
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            height: 50,
            fontFamily: "Poppins",
            fontSize: 16,
            textTransform: "none",
            paddingLeft: 25,
            paddingRight: 25,
          },
        },
        {
          props: { variant: "contained", color: "warning" },
          style: {
            height: 50,
            fontFamily: "Poppins",
            fontSize: 16,
            textTransform: "none",
            paddingLeft: 25,
            paddingRight: 25,
            color: "white",
          },
        },
        {
          props: { variant: "contained", color: "neutral" },
          style: {
            height: 50,
            fontFamily: "Poppins",
            fontSize: 16,
            textTransform: "none",
            paddingLeft: 25,
            paddingRight: 25,
            color: colorTheme.palette.primary.main,
          },
        },
        {
          props: { variant: "text"},
          style: {
            fontFamily: "Poppins",
            fontSize: 14,
            height: 36,
            fontWeight: 'normal',
            textTransform: "capitalize",
          },
        }
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderBottomStyle: "solid",
          borderColor: colorTheme.palette.background.main,
        },
      },
    },
  },
});

export default theme;
