import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { OTPDialog } from "./dialogs";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

export const useAdminOTP = (onConfirm) => {
  const [isVisible, setVisible] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const dialog = () => (
    <OTPDialog
      show={isVisible}
      onCancel={() => {
        setVisible(false);
      }}
      onConfirm={() => {
        setVisible(false);
        onConfirm();
      }}
    />
  );

  const show = () => {
    //TODO: Migrate react-native-otp from v2 to v3
    onConfirm()
    // if (user.role === "operator") {
    //   setVisible(true);
    // } else {
    //   onConfirm();
    // }
  };

  return [dialog, show];
};

export const useCategory = () => {
  const { getCategories } = useContext(APIContext);

  const { data: categories } = useQuery(["categories"], () => getCategories(), {
    select: (data) => data?.data,
  });

  return categories;
};
