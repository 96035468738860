import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Card,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
  Divider,
  Hidden,
  AppBar,
  Toolbar,
  useTheme,
  styled,
  Breadcrumbs,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
  Chip,
  Grid,
} from "@mui/material";
import {
  RowBox,
  ColBox,
  ImageBox,
  ProfileImage,
  FileButton,
  WrappedText,
  PDFThumbbnail,
  EditButton,
  DeleteButton,
  Error,
  SearchBar,
  InputSelect,
  DatePicker,
  GridItems,
} from "./ui";

import { Link, NavLink } from "react-router-dom";

import { useFormContext, Controller } from "react-hook-form";
import { DealerForm, EditPasswordForm, EditProfileForm } from "./forms";
import { FileUploader } from "react-drag-drop-files";

import MenuIcon from "@mui/icons-material/Menu";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import { OperatorTable } from "./tables";

import { currencyString, startOfDay, dateTimeString } from "../utils/utils";
import { statusOptions } from "../config";
import { useCategory } from "../components/hooks";

const menuItems = [
  {
    name: "Products",
    link: "/products",
    icon: require("../assets/icons/products.png"),
  },
  {
    name: "Dealers",
    link: "/dealers",
    icon: require("../assets/icons/person.png"),
  },
  {
    name: "Orders",
    link: "/orders",
    icon: require("../assets/icons/cart.png"),
  },
  {
    name: "Catalogue",
    link: "/catalogue",
    icon: require("../assets/icons/catalogue.png"),
  },
  {
    name: "Offers",
    link: "/offer",
    icon: <LocalOfferOutlinedIcon sx={{ color: "white", mr: 1 }} />,
  },
  {
    name: "Marketing Coordinator",
    link: "/sales",
    icon: require("../assets/icons/person.png"),
  },
  {
    name: "Activity Log",
    link: "/activity",
    icon: <ListAltOutlinedIcon sx={{ color: "white", mr: 1 }} />,
  },
];

export const SideBar = (props) => {
  const theme = useTheme();

  const Container = styled("div")(({ theme }) => ({
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: 0,
    bottom: 0,
  }));

  const StyledList = styled(List)(({ theme }) => ({
    height: "100%",
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: "border-box",
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    "&:hover": { backgroundColor: theme.palette.primary.dark },
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
    marginTop: 12,
    marginBottom: 12,
  }));

  return (
    <Container sx={props?.sx}>
      <ImageBox
        src={require("../assets/logo.png")}
        sx={{ width: 68, height: "auto", my: 3.5, alignSelf: "center" }}
      />
      <StyledList onClick={props?.onSelect}>
        {menuItems.map((i) => {
          return (
            <NavLink
              to={i.link}
              key={i.name}
              style={{ textDecoration: "none" }}
            >
              {({ isActive }) => (
                <StyledListItem
                  sx={{
                    backgroundColor: isActive
                      ? theme.palette.primary.light
                      : theme.palette.primary.main,
                  }}
                >
                  {i.icon && typeof i.icon === "object" ? (
                    i.icon
                  ) : (
                    <ImageBox
                      sx={{
                        width: 24,
                        height: 24,
                        mr: 1,
                      }}
                      src={i.icon}
                    />
                  )}

                  <Typography sx={{ color: "white" }}>{i.name}</Typography>
                </StyledListItem>
              )}
            </NavLink>
          );
        })}
      </StyledList>
    </Container>
  );
};

export const NavBar = (props) => {
  const theme = useTheme();
  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: "none",
        backgroundColor: "white",
        border: "1px solid #E8E8F7",
      }}
    >
      <Toolbar>
        <Hidden mdUp>
          <IconButton
            size="large"
            edge="start"
            color="secondary"
            onClick={props?.onMenu}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <NavBarUserProfile
          user={props?.user}
          sx={{ ml: "auto" }}
          onClick={props?.onProfileClick}
        />
      </Toolbar>
    </AppBar>
  );
};

export const PageTitle = (props) => {
  return (
    <ColBox sx={props?.sx}>
      <Typography variant="h3">{props?.title}</Typography>
      <Breadcrumbs sx={{ mt: 0.7 }}>
        {props?.links?.map((l) => (
          <NavLink
            to={l.to}
            style={({ isActive }) => ({ textDecoration: "none" })}
            end
          >
            {({ isActive }) => (
              <Typography color={isActive ? "primary" : "secondary"}>
                {l.name}
              </Typography>
            )}
          </NavLink>
        ))}
      </Breadcrumbs>
    </ColBox>
  );
};

export const NavBarUserProfile = (props) => {
  const theme = useTheme();
  const { user } = props;

  return (
    <RowBox sx={{ cursor: "pointer", ...props?.sx }} onClick={props?.onClick}>
      <ProfileImage image={user?.profileUrl} variant="header" />
      <Typography>{user?.fullname}</Typography>
    </RowBox>
  );
};

export const ProfileCard = (props) => {
  const { user } = props;

  const Info = ({ title, value, sx }) => (
    <Typography variant="h5" sx={sx}>
      {`${title}: `}
      <Typography display="inline" variant="body1">
        {value}
      </Typography>
    </Typography>
  );

  return (
    <Card sx={props?.sx}>
      <RowBox
        sx={{ p: 2.5, alignItems: "flex-start", boxSizing: "border-box" }}
        fullWidth
      >
        <RowBox sx={{ mr: "auto" }}>
          <ProfileImage image={user?.profileUrl} variant="profile" rounded />
          <ColBox sx={{ ml: 2.5 }}>
            <Typography variant="h2" sx={{ mb: 0.5 }}>
              {user?.fullname}
            </Typography>
            <Typography variant="subtitle1">{user?.email}</Typography>
          </ColBox>
        </RowBox>
        <Button
          variant="contained"
          color="warning"
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={props?.onLogout}
          sx={{ mr: 1.6 }}
        >
          Logout
        </Button>
        <Button
          variant="contained"
          startIcon={<EditOutlinedIcon />}
          onClick={props?.onEdit}
        >
          Edit Profile
        </Button>
      </RowBox>
      <Divider />
      <Box sx={{ p: 2.5 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Personal Information
        </Typography>
        <RowBox>
          <ColBox sx={{ mr: 10 }}>
            <Info title="Full Name" value={user?.fullname} sx={{ mb: 1.5 }} />
            <Info title="Phone" value={user?.phoneNo} />
          </ColBox>
          <ColBox>
            <Info title="Email" value={user?.email} sx={{ mb: 1.5 }} />
            <Info title="Address" value={user?.address} />
          </ColBox>
        </RowBox>
      </Box>
    </Card>
  );
};

export const EditPasswordCard = (props) => {
  return (
    <Card sx={{ p: 2.5, ...props?.sx }}>
      <Typography variant="h2">Edit Password</Typography>
      <RowBox sx={{ mt: 2 }} fullWidth>
        <ProfileImage image={props?.profileImage} variant="profile" rounded />
        <FileButton sx={{ ml: "auto" }} onSelect={props?.onProfileChange}>
          <Button
            variant="contained"
            startIcon={<EditOutlinedIcon />}
            sx={{ mr: 1.5, ml: "auto" }}
          >
            Edit
          </Button>
        </FileButton>
        <Button
          variant="contained"
          startIcon={<DeleteOutlineOutlinedIcon />}
          color="warning"
          onClick={props?.onProfileDelete}
        >
          Delete
        </Button>
      </RowBox>
      <EditPasswordForm sx={{ mt: 2 }} />
      <RowBox fullWidth sx={{ mt: 1.6 }}>
        <Button
          variant="contained"
          sx={{ mr: 1.5, ml: "auto" }}
          onClick={props?.onEdit}
        >
          Update
        </Button>
        <Button variant="contained" color="secondary" onClick={props?.onCancel}>
          Cancel
        </Button>
      </RowBox>
    </Card>
  );
};

export const EditProfileCard = (props) => {
  return (
    <Card sx={{ p: 2.5, ...props?.sx }}>
      <Typography variant="h2">Edit Profile</Typography>
      <EditProfileForm sx={{ mt: 2 }} />
      <RowBox fullWidth sx={{ mt: 1.6 }}>
        <Button
          variant="contained"
          sx={{ mr: 1.5, ml: "auto" }}
          onClick={props?.onEdit}
        >
          Update
        </Button>
        <Button variant="contained" color="secondary" onClick={props?.onCancel}>
          Cancel
        </Button>
      </RowBox>
    </Card>
  );
};

export const OperatorListCard = (props) => {
  return (
    <Card sx={{ p: 2.5, ...props.sx }}>
      <Typography variant="h2">Operators</Typography>

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={props?.onAdd}
      >
        Add Operator
      </Button>
      <OperatorTable
        data={props?.data}
        onEdit={props?.onEdit}
        onDelete={props?.onDelete}
      />
    </Card>
  );
};

export const CatalogueCard = (props) => {
  const StyledCard = styled(Card)(() => ({
    padding: 12,
    height: 360,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    ...props.sx,
  }));

  const Preview = styled(ColBox)(() => ({
    margin: 16,
    height: 182,
    overflow: "hidden",
  }));

  const theme = useTheme();
  const { catalogue } = props;
  return (
    <Box display="inline-block" sx={{ mr: 1.5, mb: 1.5, width: "100%" }}>
      <StyledCard>
        <CardContent sx={{ p: 0 }}>
          <CardActionArea
            onClick={() => window.open(catalogue?.url)}
            sx={{
              backgroundColor: theme.palette.background.dark,
              borderRadius: 2,
            }}
          >
            <Preview center>
              <ImageBox
                src={catalogue.thumbUrl}
                width={"100%"}
                height={"100%"}
                sx={{ objectFit: "cover" }}
              />
            </Preview>
          </CardActionArea>
          <ColBox>
            <Typography variant="h4" sx={{ mt: 1 }}>
              {catalogue?.title}
            </Typography>
            {catalogue?.description && (
              <WrappedText variant="body2" sx={{ mt: 1 }}>
                {catalogue?.description}
              </WrappedText>
            )}
          </ColBox>
        </CardContent>
        <CardActions sx={{ p: 0 }}>
          <RowBox>
            <EditButton onClick={() => props?.onEdit(catalogue)} />
            <DeleteButton
              onClick={() => props?.onDelete(catalogue)}
              sx={{ ml: 1 }}
            />
          </RowBox>
          <Button
            variant="contained"
            sx={{ ml: "auto" }}
            color={catalogue?.isDisabled ? "secondary" : "primary"}
            onClick={() => props?.onEnable(catalogue)}
          >
            {catalogue?.isDisabled ? "Enable" : "Disable"}
          </Button>
        </CardActions>
      </StyledCard>
    </Box>
  );
};

export const OfferCard = (props) => {
  const StyledCard = styled(Card)(() => ({
    padding: 12,
    height: 360,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    ...props.sx,
  }));

  const Preview = styled(ColBox)(() => ({
    height: 182,
  }));

  const { offer } = props;
  return (
    <Box display="inline-block" sx={{ mr: 1.5, mb: 1.5, width: "100%" }}>
      <StyledCard>
        <CardContent
          sx={{
            p: 0,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardActionArea
            onClick={() => window.open(offer?.url)}
            sx={{
              borderRadius: 2,
            }}
          >
            <ImageBox
              src={offer.thumbUrl || offer.url}
              width={"100%"}
              height={182}
              sx={{ objectFit: "cover" }}
            />
          </CardActionArea>
          <ColBox sx={{ flexGrow: 1 }}>
            <Typography variant="h4" sx={{ mt: 1 }}>
              {offer?.title}
            </Typography>
            {offer?.description && (
              <WrappedText variant="body2" sx={{ mt: 1 }}>
                {offer?.description}
              </WrappedText>
            )}
            <Typography
              variant="subtitle2"
              sx={{ mt: "auto" }}
              color="secondary"
            >
              {`Start: ${dateTimeString(offer?.validFrom)}`}
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }} color="secondary">
              {`End: ${dateTimeString(offer?.validUntil)}`}
            </Typography>
          </ColBox>
        </CardContent>
        <CardActions sx={{ p: 0 }}>
          <RowBox>
            <EditButton onClick={props?.onEdit} />
            <DeleteButton onClick={props?.onDelete} sx={{ ml: 1 }} />
          </RowBox>
          <Button
            variant="contained"
            sx={{ ml: "auto" }}
            color={offer?.isDisabled ? "secondary" : "primary"}
            onClick={props?.onEnable}
          >
            {offer?.isDisabled ? "Enable" : "Disable"}
          </Button>
        </CardActions>
      </StyledCard>
    </Box>
  );
};

export const CreateDealerCard = (props) => {
  return (
    <Card sx={{ p: 2.5, ...props?.sx }}>
      <RowBox fullWidth>
        <Button
          variant="contained"
          color="warning"
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={props?.onDelete}
          sx={{ ml: "auto" }}
        >
          Delete this Dealer
        </Button>
      </RowBox>

      <DealerForm sx={{}} />
      <RowBox fullWidth sx={{ mt: 2 }} center>
        <Button
          variant="contained"
          color="secondary"
          onClick={props?.onCancel}
          sx={{ mr: 1.5 }}
        >
          Cancel
        </Button>
        <Button variant="contained" sx={{ ml: 1 }} onClick={props?.onSubmit}>
          {props?.isEdit ? "Submit Changes" : "Add Dealer"}
        </Button>
      </RowBox>
    </Card>
  );
};

export const ProductFileDrop = (props) => {
  const theme = useTheme();
  const { value } = props;

  const fileTypes = ["PNG", "JPG", "JPEG"];

  const DropArea = styled(ColBox)(({ theme }) => ({
    paddingTop: 30,
    paddingBottom: 30,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.border,
  }));

  return (
    <Box sx={{ width: "100%", ...props?.sx }}>
      <FileUploader
        handleChange={props?.onChange}
        name="file"
        types={fileTypes}
        classes="drop-area"
      ></FileUploader>
    </Box>
  );
};

const ProductImageList = (props) => {
  const Image = styled("img")(({ theme }) => ({
    width: 110,
    height: 110,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.border.main,
  }));

  const Delete = styled(ImageBox)(() => ({
    position: "absolute",
    width: 24,
    height: 24,
    right: -8,
    top: -8,
  }));

  if (!props?.images?.length > 0) return;

  return (
    <RowBox sx={{ mt: 3 }} fullWidth center>
      {props?.images?.map((img, index) => (
        <ColBox sx={{ position: "relative", mr: 2 }}>
          <Image src={img instanceof File ? URL.createObjectURL(img) : img} />
          <Delete
            src={require("../assets/icons/cancel.png")}
            onClick={() => props?.onDelete(index)}
          />
        </ColBox>
      ))}
    </RowBox>
  );
};

export const ProductImageController = (props) => {
  const { control, defaultValue } = useFormContext();
  const fileTypes = ["PNG", "JPG", "JPEG"];
  const theme = useTheme();

  const DropArea = styled(ColBox)(({ theme }) => ({
    paddingTop: 30,
    paddingBottom: 30,
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: theme.palette.border.main,
  }));

  const addImage = (image, prev, callback) => {
    return callback([...(prev || []), image]);
  };

  const deleteImage = (index, prev, callback) => {
    return callback(prev.filter((v, i) => index !== i));
  };

  return (
    <Controller
      control={control}
      name={props?.name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ColBox fullWidth sx={props?.sx}>
          <DropArea fullWidth>
            <FileUploader
              handleChange={(i) => addImage(i, value, onChange)}
              name="file"
              types={fileTypes}
              classes="drop-area"
            >
              <RowBox center>
                <InsertPhotoOutlinedIcon
                  sx={{ fontSize: 32, color: theme.palette.info.light }}
                />
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ cursor: "pointer", mx: 0.5 }}
                >
                  Upload a photo
                </Typography>
                <Typography variant="body1" color="secorday">
                  or drag and drop
                </Typography>
              </RowBox>
            </FileUploader>
            <ProductImageList
              images={value}
              onDelete={(i) => deleteImage(i, value, onChange)}
            />
          </DropArea>
          {error && <Error error={error} />}
        </ColBox>
      )}
    />
  );
};

export const ProductCard = (props) => {
  const theme = useTheme();
  const { product } = props;

  const StyledCard = styled(Card)(() => ({
    width: "100%",
    height: 247,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingTop: 20,
    paddingBottom: 20,
    ...props.sx,
  }));

  return (
    <Box display="inline-block" sx={{ mr: 1.5, mb: 1.5, width: "100%" }}>
      <StyledCard>
        {/* <CardMedia
          sx={{
            px: 2,
            display: "flex",
            boxSizing: "border-box",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ImageBox
            src={product?.images?.[0]}
            height={233}
            width="100%"
            style={{ objectFit: "cover" }}
          ></ImageBox>
        </CardMedia> */}
        <CardContent
          sx={{ width: "100%", px: 2, boxSizing: "border-box", py: 1 }}
        >
          <Typography variant="h4">{product?.name || product?.type}</Typography>
          <WrappedText variant="subtitle2" sx={{ mt: 1 }}>
            {product?.description || product?.category}
          </WrappedText>
        </CardContent>
        <Divider variant="fullWidth" />
        <CardContent
          sx={{ width: "100%", boxSizing: "border-box", px: 2, py: 1 }}
        >
          <ColBox>
            <Typography variant="body3">{`Cat. No: ${product?.catalogueNo}`}</Typography>
            <Typography
              variant="body3"
              sx={{ mt: 1 }}
            >{`Price: Rs ${currencyString(product?.price)} / Unit`}</Typography>
          </ColBox>
        </CardContent>
        <CardActions
          sx={{
            width: "100%",
            px: 2,
            boxSizing: "border-box",
            mt: "auto",
            py: 0,
          }}
        >
          <RowBox fullWidth sx={{ justifyContent: "space-between" }}>
            <EditButton onClick={props?.onEdit} />
            <Button
              variant="contained"
              color={product?.isDisabled ? "secondary" : "primary"}
              onClick={props?.onEnable}
            >
              {product?.isDisabled ? "Enable" : "Disable"}
            </Button>
          </RowBox>
        </CardActions>
      </StyledCard>
    </Box>
  );
};

export const OrderStatusChip = (props) => {
  const { status } = props;
  const label = {
    pending: "Pending",
    processing: "Processing",
    rejected: "Rejected",
    completed: "Completed",
  };

  const textColor = {
    pending: "#EFAF02",
    processing: "#123524",
    rejected: "#DB0A09",
    completed: "#097715",
  };

  const bgColor = {
    pending: "#EFAF021A",
    processing: "#EFAF021A",
    rejected: "#DB0A091A",
    completed: "#0977151A",
  };

  return (
    <Chip
      label={label[status]}
      icon={<CircleIcon sx={{ fontSize: 10 }} />}
      sx={{
        color: textColor[status],
        backgroundColor: bgColor[status],
        "& .MuiChip-icon": {
          color: textColor[status],
        },
        ...props?.sx,
      }}
    />
  );
};

export const OrderSearch = (props) => {
  const categories = useCategory();
  const sortOptions = [
    { name: "Ascending Date", value: "date" },
    { name: "Descending Date", value: "-date" },
  ];

  const {
    q,
    category,
    startDate,
    endDate,
    sort,
    status,
    setCategory,
    setStartDate,
    setEndDate,
    setSortBy,
    setPage,
    setStatus,
    clearFilters,
  } = props;

  return (
    <ColBox>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <ColBox>
            <Typography variant="h5">Filter</Typography>
            <InputSelect
              options={categories?.map((c) => c.name)}
              placeholder="Product Category"
              sx={{ mt: 1, height: 32 }}
              value={category}
              onChange={(v) => {
                setCategory(v);
                setPage(0);
              }}
            />
          </ColBox>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <ColBox>
            <Typography variant="h5" sx={{ mb: 1 }}>
              From Order Date & Time
            </Typography>
            <DatePicker
              value={startDate}
              onChange={setStartDate}
              placeholder="Select Date & Time"
              inputProps={{
                sx: { paddingTop: 0.4, paddingBottom: 0.4 },
              }}
            />
          </ColBox>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <ColBox>
            <Typography variant="h5" sx={{ mb: 1 }}>
              To Order Date & Time
            </Typography>
            <DatePicker
              value={endDate}
              onChange={setEndDate}
              placeholder="Select Date & Time"
              inputProps={{
                sx: { paddingTop: 0.4, paddingBottom: 0.4 },
              }}
            />
          </ColBox>
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <ColBox>
            <Typography variant="h5" sx={{ mb: 1 }}>
              Order Status
            </Typography>
            <InputSelect
              value={status}
              onChange={setStatus}
              placeholder="Status"
              options={statusOptions}
              sx={{ height: 32 }}
            />
          </ColBox>
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <ColBox>
            <Typography variant="h5">Sort By</Typography>
            <InputSelect
              options={sortOptions}
              placeholder="Order Date"
              sx={{ mt: 1, height: 32 }}
              value={sort}
              onChange={(v) => {
                setSortBy(v);
                setPage(0);
              }}
            />
          </ColBox>
        </Grid>
      </Grid>
    </ColBox>
  );
};
