import React, { createContext, useCallback, useMemo } from "react";
import axios from "axios";
import queryString from "query-string";
import _ from "lodash";

import createAuthRefreshInterceptor from "axios-auth-refresh";

import { useDispatch } from "react-redux";
import { store } from "../store/store";
import { logout, setToken } from "../store/auth";

const baseUrl = process.env.REACT_APP_API_BASE_URL + "/v1/";
console.log("Base Url", process.env);

const APIContext = createContext();
const { Provider } = APIContext;

const APIProvider = ({ children }) => {
  const dispatch = useDispatch();

  const headers = {
    "Content-Type": "application/json",
  };

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: baseUrl,
        headers: headers,
      }),
    []
  );

  const getToken = () => {
    const token = store.getState().auth.token;
    return token;
  };

  const getRefreshToken = () => {
    const token = store.getState().auth.refreshToken;
    return token;
  };

  axiosInstance.interceptors.request.use((request) => {
    const token = getToken();
    if (token) {
      request.headers["Authorization"] = `bearer ${getToken()}`;
    }
    return request;
  });

  const refreshAuthLogic = async (failedRequest) => {
    try {
      const response = await axiosInstance.post(
        "auth/refresh-tokens",
        {
          refreshToken: getRefreshToken(),
        },
        { skipAuthRefresh: true }
      );
      dispatch(setToken(response?.data));
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + response?.data?.access?.token;
      return Promise.resolve();
    } catch (err) {
      console.log("Interceptor error ", err?.response);
      if (err?.response.status === 401) {
        dispatch(logout());
      }
      throw err;
    }
  };

  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

  const login = useCallback(
    (data) => {
      return axiosInstance.post("auth/login", data);
    },
    [axiosInstance]
  );

  const addUser = useCallback(
    (data) => {
      return axiosInstance.post("user", data);
    },
    [axiosInstance]
  );

  const getOperators = useCallback(() => {
    return axiosInstance.get(`user?role=admin&limit=30`);
  }, [axiosInstance]);

  const getSalesExecutives = useCallback(
    (page = 0) => {
      return axiosInstance.get(`user?role=sales&page=${page + 1}`);
    },
    [axiosInstance]
  );

  const getDealers = useCallback(
    (page = 0, q) => {
      return axiosInstance.get(
        `user?${queryString.stringify({ page: page + 1, q, role: "dealer" })}`
      );
    },
    [axiosInstance]
  );

  const updateProfile = useCallback(
    (data) => {
      return axiosInstance.patch(`user`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    [axiosInstance]
  );

  const updateUser = useCallback(
    (id, data) => {
      return axiosInstance.patch(`user/${id}`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    [axiosInstance]
  );

  const deleteUser = useCallback(
    (id) => {
      return axiosInstance.delete(`user/${id}`);
    },
    [axiosInstance]
  );

  const createProduct = useCallback(
    (data) => {
      return axiosInstance.post(`product`, data);
    },
    [axiosInstance]
  );

  const getProducts = useCallback(
    ({ page = 1, q, type, sort, limit = 12 }) => {
      return axiosInstance.get(
        `product?${queryString.stringify({ page, q, type, sort, limit })}`
      );
    },
    [axiosInstance]
  );

  const getCategories = useCallback(() => {
    return axiosInstance.get("category");
  }, [axiosInstance]);

  const getProductNames = useCallback(() => {
    return axiosInstance.get(`product/names`);
  }, [axiosInstance]);

  const editProduct = useCallback(
    (id, data) => {
      console.log("Product Data ", data);
      return axiosInstance.post(`product/${id}`, data);
    },
    [axiosInstance]
  );

  const bulkUploadProduct = useCallback(
    (data) => {
      return axiosInstance.post(`product/bulk`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });
    },
    [axiosInstance]
  );

  const downloadProducts = useCallback(
    (q, type, sort) => {
      return axiosInstance.get(
        `product/download?${queryString.stringify({ q, type, sort })}`,
        {
          responseType: "blob",
        }
      );
    },
    [axiosInstance]
  );

  const bulkUploadDealers = useCallback(
    (data) => {
      return axiosInstance.post(`user/bulk`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });
    },
    [axiosInstance]
  );

  const downloadDealers = useCallback(() => {
    return axiosInstance.get(`user/download`, {
      responseType: "blob",
    });
  }, [axiosInstance]);

  const bulkDeleteDealers = useCallback((dealerIds) => {
    return axiosInstance.patch(`user/bulk`, {dealers: dealerIds} )
  }, [axiosInstance])

  const getOrders = useCallback(
    ({ page = 0, q, category, startDate, endDate, status, sort }) => {
      return axiosInstance.get(
        `order?${queryString.stringify(
          {
            page: page + 1,
            q,
            category,
            startDate,
            endDate,
            sort,
            status,
            confirmed: true,
          },
          {
            skipEmptyString: true,
          }
        )}`
      );
    },
    [axiosInstance]
  );

  const updateOrder = useCallback(
    (id, body) => {
      return axiosInstance.post(`order/${id}`, body);
    },
    [axiosInstance]
  );

  const downloadOrders = useCallback(
    ({ q, category, startDate, endDate, status, sort }) => {
      return axiosInstance.get(
        `order/download?${queryString.stringify({
          q,
          category,
          startDate,
          endDate,
          sort,
          status,
          confirmed: true,
        })}`,
        {
          responseType: "blob",
        }
      );
    },
    [axiosInstance]
  );

  const updateManyOrders = useCallback(
    (orderIds, status) => {
      return axiosInstance.patch(`order`, { orderIds, status });
    },
    [axiosInstance]
  );

  const getCatalogues = useCallback(
    (page = 0) => {
      return axiosInstance.get(`catalogue?page=${page + 1}`);
    },
    [axiosInstance]
  );

  const createCatalogue = useCallback(
    (data) => {
      return axiosInstance.post(`catalogue`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    [axiosInstance]
  );

  const editCatalogue = useCallback(
    (id, data) => {
      return axiosInstance.post(`catalogue/${id}`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    [axiosInstance]
  );

  const deleteCatalogue = useCallback(
    (id) => {
      return axiosInstance.delete(`catalogue/${id}`);
    },
    [axiosInstance]
  );

  const getOffer = useCallback(
    (page = 0) => {
      return axiosInstance.get(`offer?page=${page + 1}`);
    },
    [axiosInstance]
  );

  const createOffer = useCallback(
    (data) => {
      console.log("Create Offer", data);
      return axiosInstance.post(`offer`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    [axiosInstance]
  );

  const editOffer = useCallback(
    (id, data) => {
      return axiosInstance.post(`offer/${id}`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    [axiosInstance]
  );

  const deleteOffer = useCallback(
    (id) => {
      return axiosInstance.delete(`offer/${id}`);
    },
    [axiosInstance]
  );

  const getActivities = useCallback(
    (page = 0) => {
      return axiosInstance.get(
        `activity?${queryString.stringify({ page: page + 1 })}`
      );
    },
    [axiosInstance]
  );

  const apis = useMemo(
    () => ({
      login,
      addUser,
      updateUser,
      deleteUser,
      getOperators,
      updateProfile,
      getSalesExecutives,
      getProducts,
      getCategories,
      getProductNames,
      createProduct,
      editProduct,
      editProduct,
      bulkUploadProduct,
      downloadProducts,
      bulkUploadDealers,
      downloadDealers,
      bulkDeleteDealers,
      getOrders,
      updateOrder,
      updateManyOrders,
      downloadOrders,
      getDealers,
      getCatalogues,
      createCatalogue,
      editCatalogue,
      deleteCatalogue,
      getOffer,
      createOffer,
      editOffer,
      deleteOffer,
      getActivities,
    }),
    [axiosInstance]
  );

  return <Provider value={apis}>{children}</Provider>;
};

export { APIContext, APIProvider };

const toFormData = (obj, form, namespace) => {
  var fd = form || new FormData();
  var formKey;

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === "object" && obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toString());
      } else if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File) &&
        !(obj[property] instanceof Blob)
      ) {
        toFormData(obj[property], fd, property);
      } else {
        // if it's a string or a File object or Blob
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
};

const toProductFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (key === "images") {
      for (let i = 0; i < data["images"].length; i++) {
        const image = data["images"][i];
        if (image instanceof File) {
          formData.append(key, data[key][i]);
        } else {
          formData.append("images[]", data[key][i]);
        }
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};
