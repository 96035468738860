import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { Box, Button, Grid } from "@mui/material";
import { GridItems, ProgressIndicator, RowBox } from "../components/ui";
import { PageTitle, OfferCard } from "../components/app-ui";
import { AddOfferDialog, ConfirmDialog } from "../components/dialogs";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getDirtyFields, inputDateString } from "../utils/utils";
import { offerSchema } from "../utils/validation";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import AddIcon from "@mui/icons-material/Add";

export default function Offer() {
  const [page, setPage] = useState(0);
  const [offer, setOffer] = useState();
  const [shouldShowDialog, showDialog] = useState(false);
  const [shouldShowConfirm, showConfirm] = useState(false);

  const queryClient = useQueryClient();
  const { getOffer, createOffer, editOffer, deleteOffer } =
    useContext(APIContext);

  const { data, isLoading } = useQuery(["offer"], () => getOffer(page), {
    select: (data) => data?.data,
    keepPreviousData: true,
  });

  console.log(offer?.validUntil, inputDateString(offer?.validUntil));

  const form = useForm({
    resolver: yupResolver(offerSchema),
    defaultValues: {
      title: offer?.title,
      description: offer?.description,
      image: offer?.url,
      validFrom: offer?.validFrom,
      validUntil: offer?.validUntil
    },
  });

  const addMutation = useMutation((data) => createOffer(data), {
    onSuccess: (data) => {
      showDialog(false);
      queryClient.invalidateQueries(["offer"]);
      toast.success("Successfully created offer");
    },
  });

  const editMutation = useMutation(({ id, data }) => editOffer(id, data), {
    onSuccess: (data) => {
      showDialog(false);
      queryClient.invalidateQueries(["offer"]);
      toast.success("Successfully saved changes");
    },
  });

  const deleteMutation = useMutation(() => deleteOffer(offer?.id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["offer"]);
      toast.success("Successfully deleted offer");
    },
  });

  const dirtyFields = form.formState.dirtyFields;
  const submitHandler = (values) => {
    if (offer) {
      const data = getDirtyFields(values, dirtyFields);
      if (data) {
        editMutation.mutate({ id: offer?.id, data });
      } else {
        toast.error("No changes to save");
      }
    } else {
      //TODO: Check why we are sending null in API
      if (!values.description) {
        delete values?.description;
      }
      addMutation.mutate(values);
    }
  };

  const errorHandler = (error) => {
    console.log("Error ", error);
  };

  const editHandler = (offer) => {
    setOffer(offer);
    const { title, description, url, thumbUrl, validUntil, validFrom } = offer;
    form.reset({
      title,
      description,
      image: url || thumbUrl,
      validFrom: validFrom,
      validUntil: validUntil,
    });
    showDialog(true);
  };

  const deleteHandler = (offer) => {
    setOffer(offer);
    showConfirm(true);
  };

  const deleteConfirmHandler = () => {
    showConfirm(false);
    deleteMutation.mutate();
  };

  const enableHandler = (offer) => {
    setOffer(offer);
    editMutation.mutate({
      id: offer?.id,
      data: { isDisabled: !offer.isDisabled },
    });
  };

  return (
    <Box sx={{ py: 2.8, px: 2 }}>
      <PageTitle title="Offers" />
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={() => {
          setOffer();
          form.reset({ title: null, description: null, doc: null, validFrom: null, validUntil: null });
          showDialog(true);
        }}
      >
        Add Offer
      </Button>

      <RowBox sx={{ flexWrap: "wrap" }} fullWidth>
        <Grid container spacing={2}>
          <GridItems xs={12} sm={6} md={4} lg={3}>
            {data?.results?.map((item) => {
              return (
                <OfferCard
                  offer={item}
                  onEdit={() => editHandler(item)}
                  onDelete={() => deleteHandler(item)}
                  onEnable={() => enableHandler(item)}
                />
              );
            })}
          </GridItems>
        </Grid>
      </RowBox>

      <FormProvider {...form}>
        <AddOfferDialog
          show={shouldShowDialog}
          isEdit={offer}
          onCancel={() => {
            showDialog(false);
          }}
          onSubmit={form.handleSubmit(submitHandler, errorHandler)}
        />
      </FormProvider>

      <ConfirmDialog
        show={shouldShowConfirm}
        body="Are you sure to delete this offer?"
        buttonText="Delete"
        onConfirm={deleteConfirmHandler}
        onCancel={() => showConfirm(false)}
      />
      {(isLoading ||
        addMutation.isLoading ||
        editMutation.isLoading ||
        deleteMutation.isLoading) && <ProgressIndicator />}
    </Box>
  );
}
