import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@mui/material";
import {
  RowBox,
  ColBox,
  ProgressIndicator,
  SearchBar,
  InputSelect,
} from "../components/ui";

import { OrderSearch, PageTitle } from "../components/app-ui";
import { OrderTable } from "../components/tables";
import { downloadAsFile, dateString, dateTimeString } from "../utils/utils";

//API related imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { useCategory } from "../components/hooks";

export default function Orders() {
  const [page, setPage] = useState(0);
  const [q, setQuery] = useState();
  const [category, setCategory] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sort, setSortBy] = useState();
  const [status, setStatus] = useState();
  const navigate = useNavigate();
  const categories = useCategory();
  const [selectionModel, setSelectionModel] = useState();
  const [selectStatus, setSelectStatus] = useState();

  const queryClient = useQueryClient();
  const { getOrders, downloadOrders, updateManyOrders } =
    useContext(APIContext);

  const { data, isLoading } = useQuery(
    [
      "orders",
      {
        page,
        q,
        category,
        startDate: startDate?.toString(),
        endDate: endDate?.toString(),
        status,
        sort,
      },
    ],
    () =>
      getOrders({
        page,
        q,
        category,
        startDate: startDate?.toString(),
        endDate: endDate?.toString(),
        status,
        sort,
      }),
    {
      select: (data) => data?.data,
      keepPreviousData: true,
    }
  );

  const downloadMutation = useMutation(
    () =>
      downloadOrders({
        q,
        category,
        startDate: startDate?.toString(),
        endDate: endDate?.toString(),
        status,
        sort,
      }),
    {
      onSuccess: (data) => {
        const blob = data?.data;
        if (blob?.size > 0) {
          downloadAsFile(data?.data, "Order-Report.xlsx");
          toast.success("Successfully downloaded the order report");
        } else {
          toast.error("No Data available to downloaded");
        }
        queryClient.invalidateQueries("orders");
      },
    }
  );

  const updateStatusMutation = useMutation(
    ({ orderIds, status }) => updateManyOrders(orderIds, status),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("orders");
        setSelectionModel([]);
        setSelectStatus();
      },
    }
  );

  const editHandler = (user) => {
    navigate(`edit/${user.id}`, { state: user });
  };

  const downloadHandler = () => {
    downloadMutation.mutate();
  };

  const updateHandler = () => {
    updateStatusMutation.mutate({
      orderIds: selectionModel,
      status: selectStatus,
    });
  };

  const clearFilters = () => {
    setQuery();
    setCategory();
    setSortBy();
    setStartDate(null);
    setEndDate(null);
    setStatus();
    setPage(0);
  };

  console.log(data);

  return (
    <ColBox
      sx={{
        pt: 2,
        px: 2,
        height: "calc(100vh - 87px)",
        boxSizing: "border-box",
      }}
    >
      <RowBox fullWidth>
        <Typography variant="h3">Orders</Typography>
        <Button
          color="warning"
          sx={{ ml: "auto", pb: 0 }}
          onClick={clearFilters}
        >
          Clear Filters
        </Button>
        <SearchBar
          value={q}
          onChange={(q) => {
            setQuery(q);
            setPage(0);
          }}
          sx={{ backgroundColor: "white", mx: 2 }}
        />
        <Button
          variant="contained"
          color="neutral"
          startIcon={<CloudDownloadOutlinedIcon />}
          onClick={downloadHandler}
        >
          Download Order List
        </Button>
      </RowBox>

      <Card sx={{ p: 1, my: 1, width: "100%", boxSizing: "border-box" }}>
        <OrderSearch
          {...{
            q,
            category,
            startDate,
            endDate,
            sort,
            status,
            setQuery,
            setCategory,
            setStartDate,
            setEndDate,
            setSortBy,
            setPage,
            setStatus,
            clearFilters,
          }}
        />
      </Card>
      <Card sx={{ mb: 0, width: "100%", display: "flex", flexGrow: 1 }}>
        <CardContent
          sx={{
            p: 0,
            ":last-child": { pb: 0 },
            flexGrow: 1,
            display: "flex",
          }}
        >
          <OrderTable
            data={data}
            page={page}
            onPageChange={setPage}
            onEdit={editHandler}
            onUpdate={updateHandler}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            status={selectStatus}
            setStatus={setSelectStatus}
          />
        </CardContent>
      </Card>

      {(isLoading ||
        downloadMutation.isLoading ||
        updateStatusMutation.isLoading) && <ProgressIndicator />}
    </ColBox>
  );
}
